import React, { useState } from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import EmergencyMedicineBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/prasanna_kumar_mishra.png";
import Img2 from "../../assets/doctors/rajesh_kumar_mohanty.png";
import Img3 from "../../assets/doctors/user.png";
import Img4 from "../../assets/doctors/neethu_nahak.png";
// import Img5 from "../../assets/doctors/user.png";
import Img6 from "../../assets/doctors/anup_rana.png";
import G1 from "../../assets/department photos/emergency1.png";
import G2 from "../../assets/department photos/emergency2.png";
import G3 from "../../assets/department photos/emergency3.png";
import G4 from "../../assets/department photos/emergency4.png";
import G5 from "../../assets/department photos/emergency5.png";
import G6 from "../../assets/department photos/emergency6.png";

function EmergencyMedicine() {
    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    const slides = [
        {
            image: Img1,
            name: "Dr. Prasanna Kumar Mishra",
            qualification: "Prof. & Head Emergency Medicine, MBBS, MD(Anesthesiology)",
            location: "Ashwini Hospital"
        },
        {
            image: Img2,
            name: "Dr. Rajesh Mohanty",
            qualification: "Masters in Emergency Medicine",
            location: "Ashwini Hospital"
        },
        {
            image: Img3,
            name: "Dr. Sagar Roy",
            qualification: "MD(Emergency Medicine)",
            location: "Ashwini Hospital"
        },
        {
            image: Img4,
            name: "Dr. Neethu Sujala Nahak",
            qualification: "Masters in Emergency Medicine",
            location: "Ashwini Hospital"
        },
        // {
        //     image: Img5,
        //     name: "Dr. Nitish Topno",
        //     qualification: "MD(Emergency Medicine)",
        //     location: "Ashwini Trauma Centre"
        // },
        {
            image: Img6,
            name: "Dr. Anup Rana",
            qualification: "Diploma in Emergency Medicine, MRC(Emergency Medicine)",
            location: "Aditya Ashwini Hospital"
        }
    ];

    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 },
        { image: G4 },
        { image: G5 },
        { image: G6 }
    ];
    return (
        <>
            <Navbar />

            {/* Emergency Medicine Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={EmergencyMedicineBanner} alt="Emergency Medicine Banner" title="Emergency Medicine Banner"/>
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Emergency Medicine</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Emergency Medicine Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name"href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span className="text-dark">{slide.location}</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt=" Emergency Medicine Gallery" title="Emergency Medicine Department" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Emergency Medicine Gallery" title="Emergency Medicine Department" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The Emergency Department has been accreditated by NBEMS for DNB in Emergency Medicine Department (02 seats) each year since Jan/Feb 2023 session.</p>
                                        <p>Ashwini Hospital, Emergency department is a popular, trust worthy department for the people of cuttack and surrounding areas. Prompt service, quality service and team approach for patients coming with emergency problems is the key to its success. Hence, the case load is very high.</p>
                                        <p>Ashwini Hospital has several higher speciality departments like Neurology, Neurosurgery, Cardiology, Cardiac Surgery, Pulmonology and Critical Care. There departments have highly qualified and experienced consultants, who are best in their fields. Thus Ashwini Hospital Emergency Department attracts patients of acute brain stroke, status epileptius, AIDP , Myaesthenia gravis in crises, AMI, acute abdomen, metabolic emergencies, poisoning, Respiratory failure, acute kidney injury and polytrauma.</p>
                                        <p>Post pregnancy complications from nearby centres are admitted frequently.</p>
                                        <p>It has the advantage of having ER ICU with ventilators. It also has 24 hours Emergency Laboratory facilities, Radiology facilities including CT and MRI.</p>
                                        <p>Thrombolysis in acute Brain Stroke, thrombolysis in AMI, Primary PTCA in AMI, Emergency dialysis for AKI is done on a daily basis.</p>
                                        <p>Emergency Department has allotted 2 ACLS Ambulances for Emergency Patients.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Skills learnt during Training */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Skills learnt during Training</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Priotising Emergency Case</li>
                                            <li>CPR-Adult / Pediatric</li>
                                            <li>Airway Management</li>
                                            <li>Rapid Sequence Induction</li>
                                            <li>Endotracheal Intubation</li>
                                            <li>Mechanical Ventilation</li>
                                            <li>Invasive & non-invasive Ventilatory Management</li>
                                            <li>Percutaneous and open Tracheostomy</li>
                                            <li>Invasive Monitoring(CVP & Arterial Line)</li>
                                            <li>Defibrillation And Cardioversion</li>
                                            <li>Hemodialysis Catheter insertion</li>
                                            <li>Lumber Puncture</li>
                                            <li>Bed side Ultrasound / 2D Echo</li>
                                            <li>Gastric Lavage</li>
                                            <li>Thoracocentesis</li>
                                            <li>ECG / ABG Interpretation</li>
                                            <li>ICTD</li>
                                            <li>Trochar Suprapubic Cystostomy</li>
                                            <li>Diagnostic Peritoneal Lavage</li>
                                            <li>Management of Rectal Prolapse</li>
                                            <li>Manual Reduction of Paraphimosis</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmergencyMedicine;