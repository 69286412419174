import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/taiyaba_ishrat.png";

function AAHGynaecology() {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. Taiyaba Ishrat",
            qualification: "MBBS, DGO, DNB Obstetrics & Gynaecology",
            location: "Aditya Ashwini Hospital"
        }
    ];

    const opdTimings = [
        {
            doctor: 'Dr. Taiyaba Ishrat',
            schedule: [
                { day: 'MON', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'TUE', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'WED', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'THU', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'FRI', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'SAT', time: '09:30 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' }
            ]
        }
    ]

    return (
        <>
            <NavbarLocation />

            {/* Gynaecology Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                    {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name" href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                    <div className="card h-100 doctor_img">
                                        <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                        <div className="doc-text">
                                            <h3>{slide.name}</h3>
                                            <p>{slide.qualification}</p>
                                            <span className="text-dark">{slide.location}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                {opdTimings.map((opd, index) => (
                                        <div className="accordion-item" key={`opd-${index}`}>
                                            <h2 className="accordion-header" id={`heading${index}`}>
                                                <button
                                                    className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(index)}
                                                    aria-expanded={openIndex === index ? "true" : "false"}
                                                    aria-controls={`collapse${index}`}
                                                >
                                                    {opd.doctor}
                                                    <span className="toggle-text">
                                                        {openIndex === index ? "Hide" : "Show"}
                                                    </span>
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent="#opdTimingsAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <table className="table table-hover table-light">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">DAYS</th>
                                                                <th scope="col">TIMINGS</th>
                                                                <th scope="col">CENTRE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {opd.schedule.map((schedule, idx) => (
                                                                <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                    <th scope="row">{schedule.day}</th>
                                                                    <td>{schedule.time}</td>
                                                                    <td>{schedule.centre}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Facilities Of Obstetrics & Gynaecology & Female Reproductive Medicine</h1>
                                    </div>

                                    <div className="card-text">
                                        <p><span className="text-title">Maternal-foetal medicine : </span>An obstetrical subspecialty, sometimes referred to as perinatology, that focuses on the medical and surgical management of high-risk pregnancies and surgery on the foetus with the goal of reducing morbidity and mortality.</p>

                                        <p><span className="text-title">Reproductive endocrinology and infertility : </span>A subspecialty that focuses on the biological causes and interventional treatment of infertility.</p>

                                        <p><span className="text-title">Gynaecological oncology : </span>A gynaecologic subspecialty focusing on the medical and surgical treatment of women with cancers of the reproductive organs.</p>

                                        <p><span className="text-title">Female pelvic medicine and reconstructive surgery :</span>A gynaecologic subspecialty focusing on the diagnosis and surgical treatment of women with urinary incontinence and prolapse of the pelvic organs. Sometimes referred to by laypersons as “female urology”.</p>

                                        <p><span className="text-title">Family planning : </span>A gynaecologic subspecialty offering training in contraception and pregnancy termination (abortion).</p>

                                        <p>Advanced laparoscopic surgery.</p>
                                        <p>Paediatric and adolescent gynaecology.</p>
                                        <p>Menopausal and geriatric gynaecology.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AAHGynaecology;