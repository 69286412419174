import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import PediatricBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/user.png";
import Img2 from "../../assets/doctors/samadrita_roy.png";

function Pediatric() {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. Subhashree Kar",
            qualification: "MD(Pediatrics)",
            location: "Ashwini Hospital"
        },
        {
            image: Img2,
            name: "Dr. Samadrita Roy",
            qualification: "MBBS, MD(Pediatrics)",
            location: "Aditya Ashwini Hospital, Ashwini Wellness"
        }
    ];

    const opdTimings = [
        {
            doctor: "Dr. Samadrita Roy",
            schedule: [
                { day: 'MON', time: '11:00 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'TUE', time: '11:00 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'WED', time: '11:00 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'THU', time: '11:00 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'FRI', time: '11:00 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' },
                { day: 'SAT', time: '11:00 AM - 05:00 PM', centre: 'Aditya Ashwini Hospital' }
            ]
        }
    ]

    return (
        <>
            <Navbar />

            {/* Pediatric Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={PediatricBanner} alt="Pediatric Banner" title="Pediatric Banner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Pediatric</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Pediatric Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name"href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span className="text-dark">{slide.location}</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    <div className="accordion" id="opdTimingsAccordion">
                                        {opdTimings.map((opd, index) => (
                                            <div className="accordion-item" key={`opd-${index}`}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button
                                                        className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                        type="button"
                                                        onClick={() => toggleAccordion(index)}
                                                        aria-expanded={openIndex === index ? "true" : "false"}
                                                        aria-controls={`collapse${index}`}
                                                    >
                                                        {opd.doctor}
                                                        <span className="toggle-text">
                                                            {openIndex === index ? "Hide" : "Show"}
                                                        </span>
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`collapse${index}`}
                                                    className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-bs-parent="#opdTimingsAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <table className="table table-hover table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">DAYS</th>
                                                                    <th scope="col">TIMINGS</th>
                                                                    <th scope="col">CENTRE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {opd.schedule.map((schedule, idx) => (
                                                                    <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                        <th scope="row">{schedule.day}</th>
                                                                        <td>{schedule.time}</td>
                                                                        <td>{schedule.centre}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>At Ashwini Group of Hospitals, we understand that your child's health and well-being are of utmost importance. Our dedicated team of pediatricians and healthcare professionals is committed to providing compassionate and comprehensive care to children of all ages. From routine check-ups to specialized treatments, we are here to support your child's health journey every step of the way.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Pediatric Services */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Our Pediatric Services Includes</h1>
                                    </div>

                                    <div className="card-text">
                                        <p><span className="text-title">Well-Baby and Child Check-ups : </span>Regular health assessments and developmental screenings to ensure your child is growing up healthy and strong.</p>

                                        <p><span className="text-title">Immunizations : </span>Essential vaccinations to protect your child from serious illnesses and diseases.</p>

                                        <p><span className="text-title">Pediatric Emergency Care : </span>24/7 emergency services staffed by pediatric specialists who are trained to handle critical situations with care and expertise.</p>

                                        <p><span className="text-title">Childhood Illness Management :</span>Diagnosis and treatment of common childhood illnesses such as flu, colds, infections, and more.</p>

                                        <p><span className="text-title">Chronic Disease Management : </span>Specialized care for children with chronic conditions like asthma, diabetes, allergies, and other ongoing health concerns.</p>

                                        <p><span className="text-title">Pediatric Surgery :</span>Advanced surgical procedures performed by skilled pediatric surgeons in a child-friendly environment.</p>

                                        <p><span className="text-title">Nutritional Counseling : </span>Guidance and support from nutritionists to ensure your child receives the best dietary care for healthy growth and development.</p>

                                        <p><span className="text-title">Developmental Pediatrics : </span>Evaluation and management of developmental delays, behavioral issues, and learning disabilities.</p>

                                        <p><span className="text-title">Pediatric Intensive Care Unit (PICU) : </span>State-of-the-art facilities and specialized care for critically ill children requiring intensive medical attention.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Why Choose AGH for Pediatric Care? */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Why Choose Ashwini Group of Hospitals for Pediatric Care?</h1>
                                    </div>

                                    <div className="card-text">
                                        <p><span className="text-title">Expert Team : </span>Our pediatricians and healthcare professionals are highly trained and experienced in providing specialized care for children.</p>

                                        <p><span className="text-title">Child-Friendly Environment : </span>We strive to create a warm and welcoming atmosphere designed to put children and their families at ease.</p>

                                        <p><span className="text-title">Advanced Technology : </span>We invest in the latest medical technology and equipment to ensure accurate diagnoses and effective treatments.</p>

                                        <p><span className="text-title">Family-Centered Care : </span>We believe in partnering with families to make informed decisions about their child's health and well-being.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pediatric;