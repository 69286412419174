import React from "react";
import "./Navbar.css";
import HeaderLogo from "../../assets/headerlogo.png";
import NABH from "../../assets/NABH.png";
import SEMI from "../../assets/SEMI.png";
import NBE from "../../assets/NBE.png";
import ISCCM from "../../assets/ISCCM.png";
import SmileTrain from "../../assets/SmileTrain.png";
import AahNABH from "../../assets/aah-nabh.png";
import { useLocation } from "react-router-dom";

function Navbar() {
    const location = useLocation();

    const gradeLogos = () => {
        const path = location.pathname;
        if (path.includes('AshwiniHospitalCuttack')) {
            return [NABH, SEMI, NBE, ISCCM];
        } else if (path.includes('AshwiniTraumaCentre')) {
            return [NABH, SEMI, NBE, SmileTrain];
        } else if (path.includes('AdityaAshwiniHospital')) {
            return [AahNABH];
        } else {
            return [NABH, SEMI, NBE, ISCCM]; // Default
        }
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light header-top fixed-top">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={HeaderLogo} alt="Header Logo" title="Ashwini Group Of Hospitals" />
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a href="/" className="nav-link">Home</a>
                            </li>

                            <li className="nav-item">
                                <a href="/About" className="nav-link">About Us</a>
                            </li>

                            <li className="nav-item">
                                <a href="/Departments" className="nav-link">Departments</a>
                            </li>

                            <li className="nav-item">
                                <a href="/Academic" className="nav-link">Academics</a>
                            </li>
                            <li className="nav-item">
                                <a href="/Career" className="nav-link">Career</a>
                            </li>

                            <li className="nav-item">
                                <a href="/News&Media" className="nav-link">News & Media</a>
                            </li>

                            <li className="nav-item">
                                <a href="/Contact" className="nav-link">Contact</a>
                            </li>
                        </ul>

                        <div className="navbar-brand">
                            {gradeLogos().map((logo, index) => (
                                <img className="me-1 gradeLogos" key={index} src={logo} alt={`Logo${index}`} title="Medical Grades" />
                            ))}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
