import EconomyHealthPackage from "../../assets/health_package_economy.png";
import MasterHealthPackage from "../../assets/health_package_master.png";
import ExecutiveHealthPackage from "../../assets/health_package_executive.png";
import CardiacHealthPackage from "../../assets/health_package_cardiac.png";
import SeniorCitizenHealthPackage from "../../assets/health_package_senior_citizen.png";
import WomensHealthPackage from "../../assets/health_package_womens.png";
export const healthPackages = [
    {
        id: 1,
        title: "Ashwini Economy Health Check-ups",
        image: EconomyHealthPackage,
        alt: "EconomyHealthPackage",
        modalId: "firstModal",
        price: "₹3500",
        services: ["HB", "DC", "TLC", "ESR", "FBS", "PPBS", "Na+", "K+", "Urea Creatinine", "LIVER FUNCTION TEST", "PFT", "ECG", "URINE R/M", "CHEST X-RAY", "PHYSICIAN CONSULTATION"]
    },
    {
        id: 2,
        title: "Ashwini Master Health Check-ups",
        image: MasterHealthPackage,
        alt: "MasterHealthPackage",
        modalId: "secondModal",
        price: "₹5600",
        services: ["HB", "DC", "TLC", "ESR", "FBS", "PPBS", "Na+", "K+", "Urea Creatinine", "LIVER FUNCTION TEST", "PFT", "ECG", "URINE & STOOL R/M", "VDRL", "CHEST X-RAY", "ECHO SCREENING", "LIPID PROFILE", "URIC ACID", "HIV", "HBSAG", "ULTRA SONOGRAPHY", "PHYSICIAN CONSULTATION"]
    },
    {
        id: 3,
        title: "Ashwini Executive Health Check-ups",
        image: ExecutiveHealthPackage,
        alt: "ExecutiveHealthPackage",
        modalId: "thirdModal",
        price: "₹8400",
        services: ["HB", "DC", "TLC", "ESR", "FBS", "PPBS", "Na+", "K+", "Urea Creatinine", "LIVER FUNCTION TEST", "PFT", "ECG", "URINE R/M", "CHEST X-RAY", "TMT", "ECHO DOPPLER", "LIPID PROFILE", "URIC ACID", "HIV", "HBSAG", "ULTRA SONOGRAPHY", "CARDIOLOGY CONSULTATION", "PHYSICIAN CONSULTATION"]
    },
    {
        id: 4,
        title: "Ashwini Cardiac Health Check-ups",
        image: CardiacHealthPackage,
        alt: "CardiacHealthPackage",
        modalId: "fourthModal",
        price: "₹6800",
        services: ["FBS", "PPBS", "ESR", "PFT", "ECG", "LIPID PROFILE", "CHEST X-RAY", "CARDIAC ECHO DOPPLER", "TMT", "ULTRA SONOGRAPHY", "CARDIOLOGY CONSULTATION"]
    },
    {
        id: 5,
        title: "Ashwini Senior Citizen Health Check-ups",
        image: SeniorCitizenHealthPackage,
        alt: "SeniorCitizenHealthPackage",
        modalId: "fifthModal",
        price: "₹5900",
        services: ["CBC", "FBS", "PPBS", "ESR", "Na+", "K+", "Urea Creatinine", "PTT", "URINE R/M", "LIVER FUNCTION TEST", "ECG", "LIPID PROFILE", "ECHO DOPPLER", "ULTRA SONOGRAPHY", "PHYSICIAN CONSULTATION"]
    },
    {
        id: 6,
        title: "Ashwini Women's Health Check-ups",
        image: WomensHealthPackage,
        alt: "WomensHealthPackage",
        modalId: "sixthModal",
        price: "₹3500",
        services: ["CBC", "ECG", "Ur/Cr", "ECHO", "FBS /2hr PPBS", "TMT", "CHEST X-RAY", "LIPIDS", "URINE R/M"]
    }
];