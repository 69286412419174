import React, { useState, useRef } from "react";
import './Career.css';
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import CareerBanner from "../../assets/about_banner.jpg";
import BannerBg from "../../assets/banner-callus.png";
import emailjs from '@emailjs/browser';
import jobs from '../Data/jobs';

function Career() {
    const form = useRef();
    const [selectedJob, setSelectedJob] = useState("");
    const [modalData, setModalData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_tj5tyam', 'template_s01htbp', form.current, { publicKey: 'egxcYC6EeJdXDm0et' })
            .then((result) => {
                console.log('SUCCESS!', result.text);
                alert('Email sent successfully');
            }, (error) => {
                console.log('FAILED...', error);
                alert('Failed to send email');
            });

        e.target.reset();
    };

    const handleApplyClick = (jobName) => {
        setSelectedJob(jobName);
        document.getElementById('apply-section').scrollIntoView({ behavior: 'smooth' });
    };

    const handleDetailsClick = (job) => {
        setModalData(job);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setModalData(null);
        setIsModalOpen(false);
    };

    // Filter jobs to show only those that have not exceeded the apply-by date
    // const filteredJobs = jobs.filter(job => {
    //     const today = new Date();
    //     const applyByDate = new Date(job.date);
    //     return applyByDate >= today;
    // });
    // Filter active jobs
    const activeJobs = jobs.filter(job => job.active);


    return (
        <>
            <Navbar />
            

            {/* Career Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={CareerBanner} alt="CareerBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Career</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Job Listings */}
            <section className="job-listings mb-4">
                <div className="container job">
                    <div className="sec-title">
                        <h1>Available Job Positions</h1>
                    </div>
                    <div className="row">
                        {activeJobs.map((job,key) => (
                            <div key={key} className="col-lg-4 col-md-6 mb-4">
                                <div className="card">
                                    <div className="card-body">
                                      <div className="job-title-info mb-2">
                                      <div className="job-title-heading">
                                        <h5 className="card-title custom-text m-0">{job.name}</h5>
                                        <div className="d-flex">
                                        <CiLocationOn/>
                                        <h6 className="location-heading text-secondary">{job.location}</h6>
                                        </div>
                                        </div>
                                        <button
                                                onClick={() => handleDetailsClick(job)}
                                                className="details-button"
                                            >
                                                View Details
                                            </button>
                                      </div>
                                        <p className="card-text">{job.description}</p>
                                        <p className="card-text">
                                            <small className="text-muted">
                                                <span className="text-secondary">Experience: </span>{job.experience}
                                            </small>
                                        </p>
                                        {/* <p className="card-text">
                                            <small className="text-muted">
                                                <span className="text-danger">Apply by: </span>{job.date}
                                            </small>
                                        </p> */}
                                        <div className="button-group">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => handleApplyClick(job.name)}
                                            >
                                                Apply
                                            </button>
                                            <a href={job.link} className="btn btn-success" target="_blank" rel="noopener noreferrer">
                                                <p className="m-0">Naukri</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Job Details Modal */}
            {isModalOpen && modalData && (
                <div className="job-modal" style={{ display: 'block' }}>
                    <div className="job-modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <h2 className="text-danger">Job Description</h2>
                        <p>{modalData.details}</p>
                        <p><strong>Group:</strong> {modalData.group_name}</p>
                        <p><strong>Location:</strong> {modalData.location}</p>
                        <p><strong>Experience:</strong> {modalData.experience}</p>
                        <p><strong>Education:</strong> {modalData.education}</p>
                        <p><strong>Department:</strong> {modalData.department}</p>
                        <p><strong>Employment Type:</strong> {modalData.employment_type}</p>
                        {/* <p><strong>Apply by:</strong> {modalData.date}</p> */}
                    </div>
                </div>
            )}

            {/* Career */}
            <section id="apply-section" className="appointment-section py-5">
                <div className="bg-layer">
                    <img src={BannerBg} alt="BannerBackground" />
                </div>

                <div className="container">
                    <div className="sec-title mt-4">
                        <h1>Build your Career With Us</h1>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="form-inner">
                                <div className="sec-title">
                                    <h2 className="job">Looking for a job?</h2>
                                    <p className="text-start">Don't see a role that matches you? Don't worry. Drop your resume below and we will get in touch with you.</p>
                                </div>

                                <form className="default-form" ref={form} onSubmit={sendEmail}>
                                    <div className="mb-3 form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name*"
                                            name="user_name"
                                            required />
                                    </div>

                                    <div className="row">
                                        <div className="mb-3 form-group col-sm-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Phone Number*"
                                                name="user_phone"
                                                required />
                                        </div>
                                        <div className="mb-3 form-group col-sm-6">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email address*"
                                                name="user_email"
                                                required />
                                        </div>
                                    </div>

                                    <div className="mb-3 form-group">
                                        <select
                                            className="form-control"
                                            name="user_position"
                                            required
                                            value={selectedJob}
                                            onChange={(e) => setSelectedJob(e.target.value)}
                                        >
                                            <option value="">Select Position</option>
                                            {jobs.map(job => (
                                                <option key={job.id} value={job.name}>{job.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="text-start mb-3">
                                        <button type="submit" className="theme-btn btn-one">
                                            <span>Apply Now</span>
                                        </button>
                                    </div>
                                </form>

                                <p className="resume">Drop your resume to the following email address: <span>hrd@ashwinihospitalcuttack.com</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Career;
