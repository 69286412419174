import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/sibani_das.jpg";

function ATCMaxilloFacialSurgery() {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. Sibani Das",
            qualification: "MDS(Oral & Maxillofacial Surgery)",
            location: "Ashwini Trauma Centre"
        }
    ];

    const opdTimings = [
        {
            doctor: 'Dr. Sibani Das',
            schedule: [
                { day: 'TUE', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'THU', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'SAT', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        }
    ]

    return (
        <>
            <NavbarLocation />

            {/* Maxillo Facial Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                    {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name" href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                    <div className="card h-100 doctor_img">
                                        <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                        <div className="doc-text">
                                            <h3>{slide.name}</h3>
                                            <p>{slide.qualification}</p>
                                            <span className="text-dark">{slide.location}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                {opdTimings.map((opd, index) => (
                                        <div className="accordion-item" key={`opd-${index}`}>
                                            <h2 className="accordion-header" id={`heading${index}`}>
                                                <button
                                                    className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(index)}
                                                    aria-expanded={openIndex === index ? "true" : "false"}
                                                    aria-controls={`collapse${index}`}
                                                >
                                                    {opd.doctor}
                                                    <span className="toggle-text">
                                                        {openIndex === index ? "Hide" : "Show"}
                                                    </span>
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent="#opdTimingsAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <table className="table table-hover table-light">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">DAYS</th>
                                                                <th scope="col">TIMINGS</th>
                                                                <th scope="col">CENTRE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {opd.schedule.map((schedule, idx) => (
                                                                <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                    <th scope="row">{schedule.day}</th>
                                                                    <td>{schedule.time}</td>
                                                                    <td>{schedule.centre}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Oral & Maxillo-Facial Surgery is divided into paediatric and adult Maxillo-facial surgery. Paediatric cases mostly revolve around the treatment of congenital anomalies of the craniofacial skeleton and soft tissues, such as cleft lip and palate, craniosynostosis, and paediatric fractures. Adult craniofacial surgery deals mostly with fractures (of mandible, maxilla, zygoma, frontal and naso-ethmoidal area) and secondary surgeries (such as orbital reconstruction) along with orthognathic surgery. Craniofacial surgery is an important part of all plastic surgery training programs, further training and subspecialisation is obtained via a craniofacial fellowship. Craniofacial surgery is also practiced by Maxillo-Facial surgeons.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ATCMaxilloFacialSurgery;