import React from "react";
import "../AGH.css";
import NavbarLocation from "../../Navbar/NavbarLocation";
import AAHBanner from "../../../assets/AAHBanner.png";
import Slider from "react-slick";
import AAHImage from "../../../assets/AAH.png";
import Professionalstaff from "../../../assets/professional-staff.png";
import EmergencyCase from "../../../assets/emergency-case.png";
import OnlineAppointment from "../../../assets/online-appointment.png";
import FullService from "../../../assets/247-service.png";
import Neurology from "../../../assets/service bg images/neurology.png";
import NeuroSurgery from "../../../assets/service bg images/neurosurgery.png";
import Orthopedics from "../../../assets/service bg images/orthopedics.png";
import EmergencyMedicine from "../../../assets/service bg images/emergency_medicine.png";
import ENT from "../../../assets/service bg images/ENT.png";
import Gynecology from "../../../assets/service bg images/gynecology.png";
import CriticalCare from "../../../assets/service bg images/critical_care.png";
import Pulmonology from "../../../assets/service bg images/pulmonary.png";
import Cardiology from "../../../assets/service bg images/cardiology.png";
import Nephrology from "../../../assets/service bg images/nephrology.png";
import GeneralSurgery from "../../../assets/service bg images/general_surgery.png";
import Urology from "../../../assets/service bg images/urology.png";
import Pediatrics from "../../../assets/service bg images/pediatric.png";
import Anesthesiology from "../../../assets/service bg images/anesthesiology.png";
import Radiology from "../../../assets/service bg images/radiology.png";
import Physiotherapy from "../../../assets/service bg images/physiotherapy.png";
import Dietetics from "../../../assets/service bg images/dietetics.png";
import LaboratoryServices from "../../../assets/service bg images/pathology.png";

import NeuroIcon from "../../../assets/services icons/neurology.png";
import NeurosurgeryIcon from "../../../assets/services icons/neurosurgery.png";
import OrthoIcon from "../../../assets/services icons/orthopedics.png";
import EMIcon from "../../../assets/services icons/emergency medicine.png";
import ENTIcon from "../../../assets/services icons/ent.png";
import GynecIcon from "../../../assets/services icons/gynocology.png";
import CCIcon from "../../../assets/services icons/critical care.png";
import PulmoIcon from "../../../assets/services icons/pulmonology.png";
import CardiologyIcon from "../../../assets/services icons/cardiology.png";
import NephrologyIcon from "../../../assets/services icons/nephrology.png";
import GSIcon from "../../../assets/services icons/general surgery.png";
import UrologyIcon from "../../../assets/services icons/nephrology.png";
import PediatricsIcon from "../../../assets/services icons/pediatric.png";
import AnesthesiologyIcon from "../../../assets/services icons/anesthesiology.png";
import RadiologyIcon from "../../../assets/services icons/rediology.png";
import PhysiotherapyIcon from "../../../assets/services icons/physiotherapy.png";
import DieteticsIcon from "../../../assets/services icons/diet.png";
import LaboratoryServicesIcon from "../../../assets/services icons/pathology.png";
import CourseBg1 from "../../../assets/shapes/hexagontop3D.png";

function AdityaAshwiniHospital() {
    const settings = {
        arrows: false,
        dots: false,
        fade: 1000,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const chooseUsData = [
        {
            title: 'Professional Staff',
            description: 'A skilled team providing exceptional, specialized medical care.',
            icon: Professionalstaff
        },
        {
            title: 'Emergency Case',
            description: 'Rapid response and expert care for critical medical emergencies onsite.',
            icon: EmergencyCase
        },
        {
            title: 'Online Appointment',
            description: 'Streamline medical consultations with easy online booking.',
            icon: OnlineAppointment
        },
        {
            title: '24/7 Services',
            description: 'Round-the-clock medical services ensuring care whenever you need it.',
            icon: FullService
        }
    ];

    const services = [
        {
            link: "/AdityaAshwiniHospital/Neurology",
            image: Neurology,
            icon: NeuroIcon,
            title: "Neurology"
        },
        {
            link: "/AdityaAshwiniHospital/Neurosurgery",
            image: NeuroSurgery,
            icon: NeurosurgeryIcon,
            title: "Neurosurgery"
        },
        {
            link: "/AdityaAshwiniHospital/Orthopedic",
            image: Orthopedics,
            icon: OrthoIcon,
            title: "Orthopedics & Trauma"
        },
        {
            link: "/AdityaAshwiniHospital/Emergency",
            image: EmergencyMedicine,
            icon: EMIcon,
            title: "Emergency Medicine"
        },
        {
            link: "/AdityaAshwiniHospital/ENT",
            image: ENT,
            icon: ENTIcon,
            title: "ENT Department"
        },
        {
            link: "/AdityaAshwiniHospital/Gynaecology",
            image: Gynecology,
            icon: GynecIcon,
            title: "Obstetrics & Gynaecology"
        },
        {
            link: "/AdityaAshwiniHospital/CriticalCare",
            image: CriticalCare,
            icon: CCIcon,
            title: "Critical Care"
        },
        {
            link: "/AdityaAshwiniHospital/Pulmonology",
            image: Pulmonology,
            icon: PulmoIcon,
            title: "Pulmonology"
        },
        {
            link: "/AdityaAshwiniHospital/Cardiology",
            image: Cardiology,
            icon: CardiologyIcon,
            title: "Cardiology"
        },
        {
            link: "/AdityaAshwiniHospital/Nephrology",
            image: Nephrology,
            icon: NephrologyIcon,
            title: "Nephrology & Dialysis"
        },
        {
            link: "/AdityaAshwiniHospital/GeneralSurgery",
            image: GeneralSurgery,
            icon: GSIcon,
            title: "General Surgery & Laparscopic"
        },
        {
            link: "/AdityaAshwiniHospital/Urology",
            image: Urology,
            icon: UrologyIcon,
            title: "Urology"
        },
        {
            link: "/AdityaAshwiniHospital/Pediatric",
            image: Pediatrics,
            icon: PediatricsIcon,
            title: "Pediatrics"
        },
        {
            link: "/AdityaAshwiniHospital/Anesthesiology",
            image: Anesthesiology,
            icon: AnesthesiologyIcon,
            title: "Anesthesiology & Pain Management"
        },
        {
            link: "/AdityaAshwiniHospital/Radiology",
            image: Radiology,
            icon: RadiologyIcon,
            title: "Radiology"
        },
        {
            link: "/AdityaAshwiniHospital/Physiotherapy",
            image: Physiotherapy,
            icon: PhysiotherapyIcon,
            title: "Physiotherapy Rehabilitation"
        },
        {
            link: "/AdityaAshwiniHospital/Dietetics",
            image: Dietetics,
            icon: DieteticsIcon,
            title: "Diet & Dietetics"
        },
        {
            link: "/AdityaAshwiniHospital/LaboratoryServices",
            image: LaboratoryServices,
            icon: LaboratoryServicesIcon,
            title: "Laboratory Services"
        }
    ]

    const facilities = [
        { id: 1, name: "Emergency & Day Care" },
        { id: 2, name: "OPD & IPD" },
        { id: 3, name: "32 Bed Intensive Care Unit" },
        { id: 4, name: "Private Cabin" },
        { id: 5, name: "Dialysis" },
        { id: 6, name: "24*7 Ambulance Services" },
        { id: 7, name: "24*7 Laboratory Diagnostic Services" },
        { id: 8, name: "24*7 Pharmacy" },
        { id: 9, name: "Comprehensive Health Checkup Facility" },
        { id: 10, name: "Bronchoscopy" },
        { id: 11, name: "Video Bronchoscopy" },
        { id: 12, name: "TMT" },
        { id: 13, name: "Nutrition & Dietetics" },
        { id: 14, name: "Nursing Hostel" },
        { id: 15, name: "Operation Theatre" },
        { id: 16, name: "Latest Cath Lab" },
        { id: 17, name: "Cafeteria & Canteen" },
        { id: 18, name: "Generator" },
        { id: 19, name: "Attendant Waiting Lounge" },
        { id: 20, name: "Chemo Therapy" },
        { id: 21, name: "Oxygen Plant" },
        { id: 22, name: "Parking Facility" },
        { id: 23, name: "Laundry" },
        { id: 24, name: "Insurance & TPA" },
    ]

    return (
        <>
            <NavbarLocation />

            {/* Caraousel */}
            <section className="banner-style-two" id="home">
                <Slider {...settings}>
                    <div className="slider-item">
                        <img src={AAHBanner} className="w-100 slider_img" alt="Slider 1" />
                        <div className="container">
                            <div className="content-box">
                                <h1>24 Hour Emergency</h1>
                                <p>Open round the clock for convenience, quick and easy access</p>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item">
                        <img src={AAHBanner} className="w-100 slider_img" alt="Slider 2" />
                        <div className="container">
                            <div className="content-box">
                                <h1>Complete Lab Services</h1>
                                <p>Cost-efficient, comprehensive and clinical laboratory services</p>
                            </div>
                        </div>
                    </div>

                    <div className="slider-item">
                        <img src={AAHBanner} className="w-100 slider_img" alt="Slider 3" />
                        <div className="container">
                            <div className="content-box">
                                <h1>Medical Professionals</h1>
                                <p>Qualified and certified physicians for quality medical care</p>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>

            {/* Why Choose us */}
            <section className="chooseus-section" id="aboutus">
                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="content-box">
                                <div className="sec-title light">
                                    <span className="sub-title">Why Choose Us</span>
                                    <h2>Choose The Best For Your <br />Health</h2>
                                </div>

                                <div className="row clearfix">
                                    {chooseUsData.map((item, index) => (
                                        <div className="col-lg-6 col-md-6 col-sm-12 chooseus-block" key={index}>
                                            <div className="chooseus-block-one">
                                                <div className="inner-box">
                                                    <div className="icon-box">
                                                        <img src={item.icon} alt="Professionalstaff" />
                                                    </div>
                                                    <h3>{item.title}</h3>
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12 d-none d-lg-block bg-layer">
                            <img src={AAHImage} alt="Aditya Ashwini Hospital" title="Aditya Ashwini Hospital" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Departments */}
            <section className="service-section my-5" id="departments">
                <div className="container">
                    <div className="sec-title mb-4">
                        <h1>Our Departments</h1>
                    </div>
                    <div className="row">
                    {services.map((service, index) => (
                            <>
                                <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                                    <div className="service-block-one fadeInUp">
                                        <div className="inner-box">
                                            <div className="image-box">
                                                <div className="image">
                                                    <a href={service.link}>
                                                        <img src={service.image} alt={`${service.title} Img`} title={`${service.title} Department`} />
                                                    </a>
                                                </div>
                                                <div className="icon-box">
                                                    <img src={service.icon} alt="DepartmentImages" />
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <span><a href={service.link}>{service.title}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </section >

            {/* Facilities Available */}
            <section className="course-section py-4" id="facilities">
                <div className="container">
                    <div className="image-shape">
                        <div className="shape-1">
                            <img src={CourseBg1} alt="BgDots" />
                        </div>
                    </div>
                    <div className="sec-title mb-4">
                        <h1>Facilities Available</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-3 facility-available">
                        {facilities.map((facility, index) => (
                            <div className="col" key={index}>
                                <div className="facility-widget">
                                    <p>{facility.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Address */}
            <section className="py-4" id="contact">
                <div className="sec-title">
                    <h1>Contact Us</h1>
                </div>

                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-7 col-md-12 col-sm-12 ">
                            <div className="card location-classic-content">
                                <div className="row g-0 inner-box">
                                    <div className="mapouter">
                                        <div className="gmap_canvas">
                                            <iframe
                                                title="Map of Aditya Ashwini Hospital"
                                                width="830"
                                                height="340"
                                                id="gmap_canvas"
                                                src="https://maps.google.com/maps?q=Aditya+Ashwini+Hospital%2C+Plot+329%2F1929%2C+Chandrasekharpur%2C+Bhubaneswar-751017&t=&z=16&ie=UTF8&iwloc=&output=embed"
                                                frameBorder="0"
                                                scrolling="no"
                                                marginHeight="0"
                                                marginWidth="0">
                                            </iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="default-sidebar">
                                <div className="sidebar-widget">
                                    <div className="location-title">
                                        <h3>Address</h3>
                                        <p>Plot 329/1929, Kalyan Mandap, Sri Arbindonagar Rd, near BMC, Chandrasekharpur, Bhubaneswar-751017, Odisha</p>
                                    </div>
                                    <div className="location-title">
                                        <h3>Contact Info</h3>
                                        <ul>
                                            <li><span></span>Phone : 0671-2363007/08/09/10 (Fax)</li>
                                            <li>Reception : 8018888333, 7894005555</li>
                                            <li>Emergency : 7328843255</li>
                                            <li>Ambulance : 7894005555</li>
                                            <li>Email : admin.aah@ashwinihospitalcuttack.com</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* Back button */}
            <div className="text-center mb-3">
                <a href="/" className="theme-btn btn-one">
                    <span>Back to Ashwini Group</span>
                </a>
            </div>
        </>
    )
}

export default AdityaAshwiniHospital;