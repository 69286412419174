// src/components/HealthPackageDetails.jsx

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { healthPackages } from '../Data/healthPack';
import { servicesData } from '../Data/serviceData'; // Import the service data
import './HealthPackageDetails.css';
import Navbar from '../Navbar/Navbar';

function HealthPackageDetails() {
    const { name } = useParams();
    const [activeAccordion, setActiveAccordion] = useState(null);
    const decodedName = decodeURIComponent(name);
    const packageItem = healthPackages.find(pkg => pkg.title === decodedName);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!packageItem) {
        return <p>Package not found</p>;
    }

    // Fetch service details
    const getServiceDetails = (serviceName) => {
        return servicesData.find(service => service.name === serviceName);
    };
    const toggleAccordion = (index) => {
        setActiveAccordion(index === activeAccordion ? null : index);
    };
    

    return (
       <>
        <Navbar/>
        <section className="healthpackage-details">
            <div className="container">
                <div className="card h-100">
                    <div className="card-body">
                        <h5 className="card-title pack-title">{packageItem.title}</h5>
                        <p className="card-text pack-price">Price: {packageItem.price}</p>
                        
                        <h6>Services Included:</h6>
                        <table className="table h-pack">
                            <thead>
                                <tr>
                                    <th className='text-center'>Service Name</th>
                                    <th>Image</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {packageItem.services.map((serviceName, index) => {
                                    const service = getServiceDetails(serviceName);
                                    return service ? (
                                        <tr key={index}>
                                            <td className='fw-bold text-center'>{service.name}</td>
                                            <td><img src={service.image} alt={service.name} style={{ width: '50px' }} /></td>
                                            <td>{service.description}</td>
                                        </tr>
                                    ) : (
                                        <tr key={index}>
                                            <td>{serviceName}</td>
                                            <td>Details not found</td>
                                            <td>N/A</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>

                        {/* Accordion view for mobile devices */}
                        <div className="accordion  d-md-none">
                            {packageItem.services.map((serviceName, index) => {
                                const service = getServiceDetails(serviceName);
                                return (
                                    <div key={index} className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className={`accordion-button ${activeAccordion === index ? '' : 'collapsed'}`}
                                                type="button"
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                {service ? service.name : serviceName}
                                            </button>
                                        </h2>
                                        <div className={`accordion-collapse collapse ${activeAccordion === index ? 'show' : ''}`}>
                                            <div className="accordion-body">
                                                {service ? service.description : "Details not found"}
                                                {service && (
                                                    <img src={service.image} alt={service.name} style={{ width: '100px', marginTop: '10px' }} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        {/* Additional Instructions Section */}
                        <div className="instructions mt-5">
                            <h6 className='text-center fs-3'>Important Instructions</h6>
                            <ul>
                                <li>Please take prior appointments.</li>
                                <li>For appointments, Call: 9238008811, 7608005555, 7894005555.</li>
                                <li>Report at Hospital Health check-up desk at 8am on an empty stomach.</li>
                                <li>Women in the reproductive age group should inform regarding pregnancy status as X-Ray/CT should be avoided unless medically necessary.</li>
                                <li>Time taken for the check-up ranges between 4-5 hours. Breakfast will be provided by the hospital as per package.</li>
                                <li>Collect a coupon from the Health check-up desk.</li>
                                <li>Consultation and advice will be provided by the consultant based on test reports included in the package.</li>
                                <li>Additional investigations or cross consultations not included in the package will be charged extra.</li>
                                <li>Availability of test reports depends on the nature of the tests.</li>
                                <li>Bring earlier medical reports, if any.</li>
                                <li>Health check review consultation should be availed within 14 days, beyond which consultation fees will be charged in full.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
    );
}

export default HealthPackageDetails;
