import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Video from "../../assets/Video.mp4";

function Caraousel() {
    const [currentContent, setCurrentContent] = useState(0);
    const videoRef = useRef(null);

    const settings = {
        arrows: false,
        dots: false,
        fade: 1000,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 6000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const contents = [
        { start: 0, end: 10, title: "24 Hour Emergency", description: "Open round the clock for convenience, quick and easy access" },
        { start: 10, end: 20, title: "Complete Lab Services", description: "Cost-efficient, comprehensive and clinical laboratory services" },
        { start: 20, end: 30, title: "Medical Professionals", description: "Qualified and certified physicians for quality medical care" }
    ];

    useEffect(() => {
        const videoElement = videoRef.current;

        const updateContent = () => {
            const currentTime = videoElement.currentTime;
            const currentContentIndex = contents.findIndex(
                content => currentTime >= content.start && currentTime < content.end
            );

            if (currentContentIndex !== -1) {
                setCurrentContent(currentContentIndex);
            }
        };

        videoElement.addEventListener("timeupdate", updateContent);
        return () => {
            videoElement.removeEventListener("timeupdate", updateContent);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {/* Caraousel */}
            <section className="banner-style-two">
                <Slider {...settings}>
                    <div className="slider-item slidervideo">
                        <video src={Video} className="w-100 slider_video" ref={videoRef} autoPlay loop muted />
                        <div className="container">
                            <div className="content-box">
                                <h1>{contents[currentContent].title}</h1>
                                <p>{contents[currentContent].description}</p>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        </>
    )
}

export default Caraousel;