import React, { useState } from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import AlliedServicesBanner from "../../assets/about_banner.jpg";
import G1 from "../../assets/department photos/alliedservice1.png";
import G2 from "../../assets/department photos/alliedservice2.png";
import G3 from "../../assets/department photos/alliedservice3.png";
import G4 from "../../assets/department photos/alliedservice4.png";
import G5 from "../../assets/department photos/alliedservice5.png";
import G6 from "../../assets/department photos/alliedservice6.png";
import G7 from "../../assets/department photos/alliedservice7.png";
import G8 from "../../assets/department photos/alliedservice8.png";
import G9 from "../../assets/department photos/alliedservice9.png";
import G10 from "../../assets/department photos/alliedservice10.png";
import G11 from "../../assets/department photos/alliedservice11.png";

function AlliedServices() {
    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 },
        { image: G4 },
        { image: G5 },
        { image: G6 },
        { image: G7 },
        { image: G8 },
        { image: G9 },
        { image: G10 },
        { image: G11 },
    ];

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <Navbar />

            {/* Allied Services Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={AlliedServicesBanner} alt="AlliedServices Banner" title="AlliedServices Banner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Allied Services</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} title="Allied Services Department" alt="Allied Services Gallery" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Allied Services Gallery" title="Allied Services Department"style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card-body">
                                {/* Ashwini Pharmacy */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Ashwini Pharmacy</h1>
                                        </div>
                                        <div className="card-text">
                                            <p>Ashwini Pharmacy- <span style={{ fontStyle: "italic" }}>your family pharmacy</span> - is one of the reliable pharmacies in the region. Our long years of operational experience in the region and dedicated service helped us gather the trust and confidence of our customers.Ashwini Pharmacy provides advanced clinical pharmacy services and serves as a reliable source of drug information to patients and other health care professionals. Medication management and use within the hospitals are carefully monitored to meet international standards in quality healthcare and patient safety.</p>
                                            <p>Professionally qualified team of pharmacists is well trained to deliver excellent customer service 24X7. Ongoing customer loyalty program and seasonal promotions guarantee better value for money on every single visit.</p>

                                            <p>Prescription medicine along with wide range of products from various categories such as nutrition, medical equipment, skin care and personal care, joint care and rehabilitation accessories make our pharmacies the one stop shop for the health and beauty needs of the whole family.</p>
                                            <p>As a social responsibility, Ashwini Hospital has opened Pradhan Mantri Jana Ausadhi centers in Kuruda, Balasore for making medicines available to people at a cheaper cost.</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Blood Bank */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Blood Bank</h1>
                                        </div>
                                        <div className="card-text">
                                            <p>Every minute of every day, someone needs blood. Ashwini offers blood bank services for routine and emergency purposes for the emergency cases done at the hospital and any other health care provider in the city.</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Operation Theatre */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Operation Theatre</h1>
                                        </div>
                                        <div className="card-text">
                                            <p>The hospital is equipped with three specialized operation theatres and state-of-the-art facilities designed to meet international standards. All the theatres cover a wide range of surgical specialties. There is a dedicated team of theatre staff, who play an important role in ensuring the smooth recovery of every patient. There are a total of 12 well equipped Operation theatres for 350 beds and 5 modular operating suites for high-end surgical procedures.</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Department of Laboratory */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Department of Laboratory</h1>
                                        </div>
                                        <div className="card-text">
                                            <p>Ashwini Hospital has a well equipped modern laboratory. The laboratory performs all the routine and special investigations related to clinical chemistry, immunology, Endocrinology, Hematology, Microbiology, Toxicology, Cytology and Histopathology. Facilities are available for a number of profiles for the screening and follow up diseases.</p>

                                            <p>The laboratory houses very sophisticated and state-of-the-art equipments operated by well trained laboratory personnel. All the tests are performed under strict standard Quality Control Protocols by well qualified and experienced technicians with expert guidance.</p>
                                            <p>The laboratory is open 24 hours, seven days a week.</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Department of Clinical Nutrition */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Department of Clinical Nutrition</h1>
                                        </div>
                                        <div className="card-text">
                                            <p>The department of Clinical Nutrition provides Medical nutrition assessment, Nutriotional Therapy Plan and prescribes Nutritional interventions and prescribes diets to both outpatients and inpatients.</p>
                                            <p>Nutritional Medicine and Dietary Recommendation are provided for the following conditions :- </p>
                                            <ul className="ul_list">
                                                <li>Obesity and Overweight</li>
                                                <li>Systematic Hypertension</li>
                                                <li>Dyslipidemia</li>
                                                <li>Cardio-vascular disease</li>
                                                <li>Liver and Kidney Disease</li>
                                                <li>Anemia</li>
                                                <li>Hyperuricemia</li>
                                                <li>Acid Peptic Disease</li>
                                                <li>Obstetrics and Gynecology</li>
                                                <li>Sick day rules</li>
                                                <li>Diabetic Education program includes Dietary counselling, correction, prevention and causes of Hypoglycemia, Role of physical exercise in diabetes, Diabetes and Foot care, Need for self monitoring glucose, importance of maintaining a medical Diary, Prevention of long term complications.</li>
                                            </ul>
                                            <p>The clinical nutritionist also provides extensive diet counselling, and educates the patients regarding the nature of the diseases, its hazards, and how it can be recognized and prevented. Program instructions on the diet and booklets containing general guidance are also given. The department also manages cases which require Enteral Nutrition. Food service is guided and supervised by the department of clinical nutrition. The diets provided are strictly monitored, well-balanced, wholesome and nutritious pertaining as per their requirements.</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Administrative and Support Units */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Administrative and Support Units</h1>
                                        </div>
                                        <div className="card-text">
                                            <p>The administrative and support units of Ashwini Hospital provides a wide variety of important routine support functions that are vital to the day to day running of the hospital. The functions are managed by well trained and highly qualified staff from their respective fields of work.</p>
                                            <p>The support services includes :- </p>
                                            <ul className="ul_list">
                                                <li>Ambulance Services</li>
                                                <li>Quality Assurance</li>
                                                <li>Bio medical department</li>
                                                <li>Information Technology</li>
                                                <li>Human Resource</li>
                                                <li>Marketing and Business development</li>
                                                <li>Finance</li>
                                                <li>Insurance</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* Career */}
                                <div className="card about-style-two py-0">
                                    <div className="container card-body">
                                        <div className="sec-title">
                                            <h1>Career</h1>
                                        </div>
                                        <div className="card-text">
                                            <p>Ashwini group developing and helping you in fulfilling your career goals.</p>
                                            <p><span className="text-title">Individual Development Plans : </span>Colleagues work with their managers to create detailed career “roadmaps” that take into account colleague interests and aspirations as well as Pfizer's business needs.</p>

                                            <p><span className="text-title">Mentoring : </span>Pfizer actively promotes mentoring through its Mentor Match online tool - a quick and easy way to volunteer to be a mentor or to find a mentor that meets specific criteria.</p>

                                            <p><span className="text-title">Internal Advancement : </span>Colleagues may freely explore and apply for positions within Pfizer.</p>

                                            <p><span className="text-title">Coaching-Focused Performance Management Process :</span>Pfizer encourages managers to hold frequent, robust performance coaching discussions, rather than assigning labels to sum up an entire year's performance.</p>

                                            <p><span className="text-title">Short- and Medium-Term Experiential Opportunities : </span>Through activities like job-rotation assignments, experiential action projects, and short-term project roles, Pfizer offers colleagues a variety of short- and medium-term career development opportunities.</p>

                                            <p><span className="text-title">Learning & Development Opportunities : </span>Colleagues can take advantage of learning and development opportunities focused on hundreds of topics, including leadership and management skills, industry- and job-specific learning, and general business, manufacturing, finance, and technology skills.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AlliedServices;