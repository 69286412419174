import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from "./Components/Homepage/Homepage";
import AboutUs from "./Components/About/About";
import Departments from "./Components/Departments/Departments";
//  ---------- AGH Departments (start) ---------- 
import Neurology from "./Components/DepartmentsDetails/Neurology";
import Neurosurgery from "./Components/DepartmentsDetails/Neurosurgery";
import Orthopedics from "./Components/DepartmentsDetails/Orthopedics";
import PlasticSurgery from "./Components/DepartmentsDetails/PlasticSurgery";
import Pulmonary from "./Components/DepartmentsDetails/Pulmonary";
import CriticalCare from "./Components/DepartmentsDetails/CriticalCare";
import EmergencyMedicine from "./Components/DepartmentsDetails/EmergencyMedicine";
import Cardiology from "./Components/DepartmentsDetails/Cardiology";
import Cardiothoracic from "./Components/DepartmentsDetails/Cardiothoracic";
import Dermatology from "./Components/DepartmentsDetails/Dermatology";
import HandSurgery from "./Components/DepartmentsDetails/HandSurgery";
import Gynaecology from "./Components/DepartmentsDetails/Gynaecology";
import Nephrology from "./Components/DepartmentsDetails/Nephrology";
import Urology from "./Components/DepartmentsDetails/Urology";
import MaxilloFacialSurgery from "./Components/DepartmentsDetails/MaxilloFacialSurgery";
import GeneralSurgery from "./Components/DepartmentsDetails/GeneralSurgery";
import Anesthesiology from "./Components/DepartmentsDetails/Anesthesiology";
import Radiology from "./Components/DepartmentsDetails/Radiology";
import SpineSurgery from "./Components/DepartmentsDetails/SpineSurgery";
import Dental from "./Components/DepartmentsDetails/Dental";
import Pediatric from "./Components/DepartmentsDetails/Pediatric";
import Physiotherapy from "./Components/DepartmentsDetails/Physiotherapy";
import Psychology from "./Components/DepartmentsDetails/Psychology";
import Dietetics from "./Components/DepartmentsDetails/Dietetics";
import LaboratoryServices from "./Components/DepartmentsDetails/LaboratoryServices";
import AlliedServices from "./Components/DepartmentsDetails/AlliedServices";

//  ---------- Location (start) ---------- 
import AshwiniHospital from "./Components/AGH/AshwiniHospitalCuttack/AshwiniHospitalCuttack";
import AshwiniTraumaCentre from "./Components/AGH/AshwiniTraumaCentre/AshwiniTraumaCentre";
import AdityaAshwiniHospital from "./Components/AGH/AdityaAshwiniHospital/AdityaAshwiniHospital";

//  ---------- Academics (start) ---------- 
import Academics from "./Components/Academics/Academic";
import DNB from "./Components/Academics/DNB";
import DrNB from "./Components/Academics/DrNB";

import Career from "./Components/Career/Career";
import NewsMedia from "./Components/News&Media/News&Media";
import Contact from "./Components/ContactUs/Contact";
import Footer from "./Components/Footer/Footer";

// Ashwini Hospital Cuttack Page
import AHNeurology from "./Components/AGH/AshwiniHospitalCuttack/Neurology";
import AHNeurosurgery from "./Components/AGH/AshwiniHospitalCuttack/Neurosurgery";
import AHOrthopedic from "./Components/AGH/AshwiniHospitalCuttack/Orthopedic";
import AHEmergencyMedicine from "./Components/AGH/AshwiniHospitalCuttack/EmergencyMedicine";
import AHCriticalCare from "./Components/AGH/AshwiniHospitalCuttack/CriticalCare";
import AHPulmonology from "./Components/AGH/AshwiniHospitalCuttack/Pulmonology";
import AHCardiology from "./Components/AGH/AshwiniHospitalCuttack/Cardiology";
import AHCardiothoracic from "./Components/AGH/AshwiniHospitalCuttack/Cardiothoracic";
import AHNephrology from "./Components/AGH/AshwiniHospitalCuttack/Nephrology";
import AHUrology from "./Components/AGH/AshwiniHospitalCuttack/Urology";
import AHGeneralSurgery from "./Components/AGH/AshwiniHospitalCuttack/GeneralSurgery";
import AHPediatrics from "./Components/AGH/AshwiniHospitalCuttack/Pediatrics";
import AHAnesthesiology from "./Components/AGH/AshwiniHospitalCuttack/Anesthesiology";
import AHDietetics from "./Components/AGH/AshwiniHospitalCuttack/Dietetics";
import AHRadiology from "./Components/AGH/AshwiniHospitalCuttack/Radiology";
import AHPhysiotherapy from "./Components/AGH/AshwiniHospitalCuttack/Physiotherapy";
import AHLaboratoryServices from "./Components/AGH/AshwiniHospitalCuttack/LaboratoryServices";

// Ashwini Trauma Centre Page
import ATCOrthopedic from "./Components/AGH/AshwiniTraumaCentre/Orthopedic";
import ATCEmergencyMedicine from "./Components/AGH/AshwiniTraumaCentre/EmergencyMedicine";
import ATCCriticalCare from "./Components/AGH/AshwiniTraumaCentre/CriticalCare";
import ATCPlasticSurgery from "./Components/AGH/AshwiniTraumaCentre/PlasticSurgery";
import ATCHandSurgery from "./Components/AGH/AshwiniTraumaCentre/HandSurgery";
import ATCUrology from "./Components/AGH/AshwiniTraumaCentre/Urology";
import ATCGeneralSurgery from "./Components/AGH/AshwiniTraumaCentre/GeneralSurgery";
import ATCMaxilloFacialSurgery from "./Components/AGH/AshwiniTraumaCentre/MaxilloFacialSurgery";
import ATCAnesthesiology from "./Components/AGH/AshwiniTraumaCentre/Anesthesiology";
import ATCRadiology from "./Components/AGH/AshwiniTraumaCentre/Radiology";
import ATCPhysiotherapy from "./Components/AGH/AshwiniTraumaCentre/Physiotherapy";
import ATCLaboratoryServices from "./Components/AGH/AshwiniTraumaCentre/LaboratoryServices";
import ATCDietetics from "./Components/AGH/AshwiniTraumaCentre/Dietetics";

// Aditya Ashwini Hospital Page
import AAHNeurology from "./Components/AGH/AdityaAshwiniHospital/Neurology";
import AAHNeurosurgery from "./Components/AGH/AdityaAshwiniHospital/Neurosurgery";
import AAHOrthopedic from "./Components/AGH/AdityaAshwiniHospital/Orthopedic";
import AAHEmergencyMedicine from "./Components/AGH/AdityaAshwiniHospital/EmergencyMedicine";
import AAHENT from "./Components/AGH/AdityaAshwiniHospital/ENT";
import AAHGynaecology from "./Components/AGH/AdityaAshwiniHospital/Gynaecology";
import AAHCriticalCare from "./Components/AGH/AdityaAshwiniHospital/CriticalCare";
import AAHPulmonology from "./Components/AGH/AdityaAshwiniHospital/Pulmonology";
import AAHCardiology from "./Components/AGH/AdityaAshwiniHospital/Cardiology";
import AAHNephrology from "./Components/AGH/AdityaAshwiniHospital/Nephrology";
import AAHGeneralSurgery from "./Components/AGH/AdityaAshwiniHospital/GeneralSurgery";
import AAHUrology from "./Components/AGH/AdityaAshwiniHospital/Urology";
import AAHPediatrics from "./Components/AGH/AdityaAshwiniHospital/Pediatrics";
import AAHAnesthesiology from "./Components/AGH/AdityaAshwiniHospital/Anesthesiology";
import AAHRadiology from "./Components/AGH/AdityaAshwiniHospital/Radiology";
import AAHPhysiotherapy from "./Components/AGH/AdityaAshwiniHospital/Physiotherapy";
import AAHDietetics from "./Components/AGH/AdityaAshwiniHospital/Dietetics";
import AAHLaboratoryServices from "./Components/AGH/AdityaAshwiniHospital/LaboratoryServices";
import DoctorDetails from "./Components/Doctors/DoctorsDetail";
import HealthPackageDetails from "./Components/Homepage/HealthPackageDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/About" element={<AboutUs />} />
        <Route path="/Departments" element={<Departments />} />
        <Route path="/Doctors/:doctorId" element={<DoctorDetails />} />
        <Route path="/health-package/:name" element={<HealthPackageDetails />} />
        {/* ---------- AGH Departments (start) ---------- */}
        <Route path="/Neurology" element={<Neurology />} />
        <Route path="/Neurosurgery" element={<Neurosurgery />} />
        <Route path="/Orthopedics" element={<Orthopedics />} />
        <Route path="/PlasticSurgery" element={<PlasticSurgery />} />
        <Route path="/Pulmonary" element={<Pulmonary />} />
        <Route path="/CriticalCare" element={<CriticalCare />} />
        <Route path="/EmergencyMedicine" element={<EmergencyMedicine />} />
        <Route path="/Cardiology" element={<Cardiology />} />
        <Route path="/Cardiothoracic" element={<Cardiothoracic />} />
        <Route path="/Dermatology" element={<Dermatology />} />
        <Route path="/HandSurgery" element={<HandSurgery />} />
        <Route path="/Gynaecology" element={<Gynaecology />} />
        <Route path="/Nephrology" element={<Nephrology />} />
        <Route path="/Urology" element={<Urology />} />
        <Route path="/MaxilloFacialSurgery" element={<MaxilloFacialSurgery />} />
        <Route path="/GeneralSurgery" element={<GeneralSurgery />} />
        <Route path="/Anesthesiology" element={<Anesthesiology />} />
        <Route path="/Radiology" element={<Radiology />} />
        <Route path="/SpineSurgery" element={<SpineSurgery />} />
        <Route path="/Dental" element={<Dental />} />
        <Route path="/Pediatric" element={<Pediatric />} />
        <Route path="/Physiotherapy" element={<Physiotherapy />} />
        <Route path="/Psychology" element={<Psychology />} />
        <Route path="/Dietetics" element={<Dietetics />} />
        <Route path="/LaboratoryServices" element={<LaboratoryServices />} />
        <Route path="/AlliedServices" element={<AlliedServices />} />

        {/* ---------- Location (start) ---------- */}
        <Route path="/AshwiniHospitalCuttack" element={<AshwiniHospital />} />
        <Route path="/AshwiniTraumaCentre" element={<AshwiniTraumaCentre />} />
        <Route path="/AdityaAshwiniHospital" element={<AdityaAshwiniHospital />} />

        {/* ---------- Academics (start) ---------- */}
        <Route path="/Academic" element={<Academics />} />
        <Route path="/AcademicDetails/DNB" element={<DNB />} />
        <Route path="/AcademicDetails/DrNB" element={<DrNB />} />

        <Route path="/Career" element={<Career />} />
        <Route path="/News&Media" element={<NewsMedia />} />
        <Route path="/Contact" element={<Contact />} />

        {/* Ashwini Hospital Cuttack Page */}
        <Route path="/AshwiniHospitalCuttack/Neurology" element={<AHNeurology />} />
        <Route path="/AshwiniHospitalCuttack/Neurosurgery" element={<AHNeurosurgery />} />
        <Route path="/AshwiniHospitalCuttack/Orthopedic" element={<AHOrthopedic />} />
        <Route path="/AshwiniHospitalCuttack/Emergency" element={<AHEmergencyMedicine />} />
        <Route path="/AshwiniHospitalCuttack/CriticalCare" element={<AHCriticalCare />} />
        <Route path="/AshwiniHospitalCuttack/Pulmonology" element={<AHPulmonology />} />
        <Route path="/AshwiniHospitalCuttack/Cardiology" element={<AHCardiology />} />
        <Route path="/AshwiniHospitalCuttack/Cardiothoracic" element={<AHCardiothoracic />} />
        <Route path="/AshwiniHospitalCuttack/Nephrology" element={<AHNephrology />} />
        <Route path="/AshwiniHospitalCuttack/Urology" element={<AHUrology />} />
        <Route path="/AshwiniHospitalCuttack/GeneralSurgery" element={<AHGeneralSurgery />} />
        <Route path="/AshwiniHospitalCuttack/Pediatric" element={<AHPediatrics />} />
        <Route path="/AshwiniHospitalCuttack/Anesthesiology" element={<AHAnesthesiology />} />
        <Route path="/AshwiniHospitalCuttack/Dietetics" element={<AHDietetics />} />
        <Route path="/AshwiniHospitalCuttack/Radiology" element={<AHRadiology />} />
        <Route path="/AshwiniHospitalCuttack/Physiotherapy" element={<AHPhysiotherapy />} />
        <Route path="/AshwiniHospitalCuttack/LaboratoryServices" element={<AHLaboratoryServices />} />

        {/* Ashwini Trauma Centre Page */}
        <Route path="/AshwiniTraumaCentre/Orthopedic" element={<ATCOrthopedic />} />
        <Route path="/AshwiniTraumaCentre/Emergency" element={<ATCEmergencyMedicine />} />
        <Route path="/AshwiniTraumaCentre/CriticalCare" element={<ATCCriticalCare />} />
        <Route path="/AshwiniTraumaCentre/PlasticSurgery" element={<ATCPlasticSurgery />} />
        <Route path="/AshwiniTraumaCentre/HandSurgery" element={<ATCHandSurgery />} />
        <Route path="/AshwiniTraumaCentre/Urology" element={<ATCUrology />} />
        <Route path="/AshwiniTraumaCentre/GeneralSurgery" element={<ATCGeneralSurgery />} />
        <Route path="/AshwiniTraumaCentre/MaxilloFacialSurgery" element={<ATCMaxilloFacialSurgery />} />
        <Route path="/AshwiniTraumaCentre/Anesthesiology" element={<ATCAnesthesiology />} />
        <Route path="/AshwiniTraumaCentre/Dietetics" element={<ATCDietetics />} />
        <Route path="/AshwiniTraumaCentre/Radiology" element={<ATCRadiology />} />
        <Route path="/AshwiniTraumaCentre/Physiotherapy" element={<ATCPhysiotherapy />} />
        <Route path="/AshwiniTraumaCentre/LaboratoryServices" element={<ATCLaboratoryServices />} />

        {/* Aditya Ashwini Hospital Page */}
        <Route path="/AdityaAshwiniHospital/Neurology" element={<AAHNeurology />} />
        <Route path="/AdityaAshwiniHospital/Neurosurgery" element={<AAHNeurosurgery />} />
        <Route path="/AdityaAshwiniHospital/Orthopedic" element={<AAHOrthopedic />} />
        <Route path="/AdityaAshwiniHospital/Emergency" element={<AAHEmergencyMedicine />} />
        <Route path="/AdityaAshwiniHospital/ENT" element={<AAHENT />} />
        <Route path="/AdityaAshwiniHospital/Gynaecology" element={<AAHGynaecology />} />
        <Route path="/AdityaAshwiniHospital/CriticalCare" element={<AAHCriticalCare />} />
        <Route path="/AdityaAshwiniHospital/Pulmonology" element={<AAHPulmonology />} />
        <Route path="/AdityaAshwiniHospital/Cardiology" element={<AAHCardiology />} />
        <Route path="/AdityaAshwiniHospital/Nephrology" element={<AAHNephrology />} />
        <Route path="/AdityaAshwiniHospital/GeneralSurgery" element={<AAHGeneralSurgery />} />
        <Route path="/AdityaAshwiniHospital/Urology" element={<AAHUrology />} />
        <Route path="/AdityaAshwiniHospital/Pediatric" element={<AAHPediatrics />} />
        <Route path="/AdityaAshwiniHospital/Anesthesiology" element={<AAHAnesthesiology />} />
        <Route path="/AdityaAshwiniHospital/Radiology" element={<AAHRadiology />} />
        <Route path="/AdityaAshwiniHospital/Physiotherapy" element={<AAHPhysiotherapy />} />
        <Route path="/AdityaAshwiniHospital/Dietetics" element={<AAHDietetics />} />
        <Route path="/AdityaAshwiniHospital/LaboratoryServices" element={<AAHLaboratoryServices />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
