const jobs = [
    {
        id: 1,
        name: "Medical Officer",
        description: "Provide patient care, conduct medical assessments, and oversee clinical operations ensuring optimal healthcare services.",
        experience: "0 - 2 Years",
        details: "Primary medical care to patients, diagnosing,consultations,treating common medical conditions,perform physical examinations,administer treatments.Patients care,respond to emergencies & stabilization to patients. Maintaining medical history & MRD.Required Candidate profile Medical degree (MBBS or equivalent) from a recognized medical institution, Valid medical Registration.Completion of an internship or residency program in a clinical setting",
        link: "https://www.naukri.com/job-listings-Medical-Officer-For-a-Super-Specialty-Hospital-Cuttack-Hospital-Bhubaneswar-Cuttack-0-to-2-years-030924017911",
        location: "Bhubaneswar , Cuttack",
        education: "BAMS in Any Specialization, MBBS in Any Specialization",
        department: "Healthcare & Life Sciences",
        employment_type: "Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 2,
        name: "Dermatologist",
        description: "Provide skin care treatments, diagnose skin conditions, and perform cosmetic procedures as needed.",
        experience: "2 - 7 Years",
        details: "Consultation & patient diagnosis, perform/supervise all medical procedures on Derma led services, ensuring safety standards during procedures. Consultation, Diagnosis, Ensuring Safety & process adherence, Presentable, Good Communication.Required Candidate profile with MBBS+MD/DNB in Dermatology.Please send you resume to hrd@ashwinihospitalcuttack.com or call in 9238008813",
        link: "https://www.naukri.com/job-listings-Dermatologist-Female-Candidates-Preferred-Cuttack-Hospital-Bhubaneswar-2-to-7-years-050924016752",
        location: "Bhubaneswar",
        education: "Medical-MS/MD in Dermatology",
        department: "Fitness & Wellness",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 3,
        name: "Consultant-Cosmetology",
        description: "Provide expert skincare, hair, and cosmetic treatments, consultations, and personalized solutions for client aesthetic needs.",
        experience: "2 - 7 Years",
        details: "Consultation & diagnosis, Derma led services, skin Treatment, Aesthetic laser treatment, therapist/ Technician, Beauty Advisor, Aesthetic Consultant, Cosmetology Instructor, Spa Treatment, Product Assistance. Required Candidate profile Diploma/ corticate in Cosmetology.Please send you resume to hrd@ashwinihospitalcuttack.com or call in 9238008813",
        link: "https://www.naukri.com/job-listings-Cosmetologist-Consultant-Tattva-Cuttack-Hospital-Bhubaneswar-2-to-7-years-040924008828",
        location: "Bhubaneswar",
        education: "Any Graduate",
        department: "Healthcare & Life Sciences",
        employment_type: "Full Time, Permanent",
        group_name:"Tattva",
        active: true
    },
    {
        id: 4,
        name: "Nursing Superintendent",
        description: "Oversee nursing staff, ensure high-quality patient care, manage operations, and uphold healthcare standards.",
        experience: "10 - 20 Years",
        details: "Leadership to the Nursing Dept. & ensure smooth management to maintain satisfactory schedule of nursing care, Daily round and medical records.Continual training plan. Project shortfall, Manage rosters, leaves and rotational shifts of nursing staff.",
        link: "https://www.naukri.com/job-listings-Nursing-Superintendent-Ashwini-Group-of-Hospitals-Ashwini-Hospital-Cuttack-10-to-20-years-030924017942",
        location: "Cuttack",
        education: "B.Sc in Nursing",
        department: "Healthcare & Life Sciences",
        employment_type: "Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 5,
        name: "Web Developer",
        description: "Develop , design and maintain responsive web applications using React/Angular/.NET/full-stack solutions.",
        experience: "2 - 7 Years",
        details: "Develop and maintain web applications, Design, implement, test user interfaces and back-end services.Optimize applications for maximum speed and scalability. Troubleshoot & debug applications for optimal perfomance.Ensure code quality & maintain code. Required Candidate profile B.Tech in Computer Science, BCA/MCA/B.SC with relevant Computer Diploma With a minimum 2 years exp. as Web Developer.",
        link: "https://www.naukri.com/job-listings-Web-Developer-React-Angular-NET-Full-Stack-Developer--Ashwini-Hospital-Bhubaneswar-Cuttack-2-to-7-years-050824014068",
        location: "Bhubaneswar , Cuttack",
        education: "Diploma in Any Specialization, Any Graduate, BCA in Any Specialization, B.Tech/B.E. in Any Specialization",
        department: "UX, Design & Architecture",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    },
    {
        id: 6,
        name: "MOD/PRO",
        description: "Coordinate patient care, handle grievances, ensure smooth hospital operations, and maintain high patient satisfaction.",
        experience: "2 - 3 Years",
        details: "The Medical Officer/Patient Relations Officer (MOD/PRO) plays a crucial role in ensuring the smooth operations of hospital services and maintaining high patient satisfaction. The MOD/PRO will be responsible for coordinating between various departments, addressing patient concerns, and ensuring a positive patient experience from admission to discharge.",
        link: "",
        location: "Bhubaneswar , Cuttack",
        education: " MBBS/Graduate in healthcare management or relevant field preferred.",
        department: "",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    },
    {
        id: 6,
        name: "Consultant-Neurosurgery",
        description: "Perform complex brain and spinal surgeries, diagnose neurological conditions, and provide patient care and treatment.",
        experience: "2 - 7 Years",
        details: "We are seeking a skilled and compassionate Neurosurgeon to join our medical team. The Neurosurgeon will be responsible for performing complex surgical procedures on the brain, spinal cord, and peripheral nerves. The ideal candidate will have extensive experience in neurosurgery, strong diagnostic skills, and a commitment to providing high-quality patient care.Fellowship training in a subspecialty of neurosurgery.Experience with advanced neurosurgical techniques and technologies.Research experience in neurosurgery or related fields.",
        link: "https://www.naukri.com/job-listings-consultant-neurosurgery-ashwini-hospital-cuttack-2-to-7-years-200924016447?src=jobsearchDesk&sid=17276887918471435&xp=9&px=1&nignbevent_src=jobsearchDeskGNB",
        location: "Cuttack",
        education: " MCH/DNB in Neurosurgery.",
        department: "Neurology",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 7,
        name: "Consultant-Cardiology",
        description: "Diagnose and treat heart conditions, perform procedures, and provide patient care and management.",
        experience: "2 - 7 Years",
        details: "We are seeking a skilled and compassionate Cardiologist to diagnose, treat, and manage patients with cardiovascular conditions. The ideal candidate will have extensive experience in evaluating heart health, performing diagnostic tests, and providing comprehensive care to patients with heart disease and related conditions.",
        link: "https://www.naukri.com/job-listings-consultant-cardiology-ashwini-hospital-cuttack-2-to-7-years-260924007945?src=jobsearchDesk&sid=17276887918471435&xp=7&px=1&nignbevent_src=jobsearchDeskGNB",
        location: "Cuttack",
        education: " DM/DNB in Cardiology.",
        department: "Cardiology",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 8,
        name: "Consultant-Radiology",
        description: "Diagnose and interpret medical images; provide radiology consultations and ensure accurate imaging results.",
        experience: "1 - 6 Years",
        details: "We are seeking a skilled and dedicated Radiologist to join our medical team. The ideal candidate will be responsible for diagnosing and interpreting medical images to provide accurate and timely diagnoses. The Radiologist will work closely with other healthcare professionals to ensure high-quality patient care and contribute to the development of effective treatment plans.Provide expert consultations and collaborate with physicians to discuss imaging findings and treatment options.",
        link: "https://www.naukri.com/job-listings-consultant-radiology-ashwini-hospital-cuttack-1-to-6-years-280924008234?src=jobsearchDesk&sid=17276887918471435&xp=4&px=1&nignbevent_src=jobsearchDeskGNB",
        location: "Cuttack",
        education: "DM/MD/DNB/DMRD in Radiology",
        department: "Radiology",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 9,
        name: "Consultant-Critcal Care",
        description: "Provide expert care for critically ill patients, manage intensive care units, and collaborate on treatment plans.",
        experience: "2 - 7 Years",
        details: " The Critical Care Consultant will provide specialized care for critically ill patients in an intensive care setting. This role involves managing and supervising the care of patients with severe or life-threatening conditions, coordinating with multidisciplinary teams, and ensuring the delivery of high-quality medical services.Engage in continuous education and professional development to stay updated with advancements in critical care medicine.",
        link: "https://www.naukri.com/job-listings-consultant-critical-care-ashwini-hospital-cuttack-2-to-7-years-200924016708?src=jobsearchDesk&sid=17276887918471435&xp=8&px=1&nignbevent_src=jobsearchDeskGNB",
        location: "Cuttack",
        education: "MD/DNB - Anaesthesia, Neuro-anaesthesia, DM Critical Care, IDCCM, CTCCM, MBBS.",
        department: "Critical Care",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 10,
        name: "Anesthesiologist",
        description: "Administers anesthesia, monitors patient vitals during surgery, and ensures patient comfort and safety.",
        experience: "2 - 4 Years",
        details: "As an Anesthesiologist, you will play a crucial role in ensuring patient safety and comfort during surgical procedures.Administer various types of anesthesia (general, regional, local) as required for surgical procedures.Respond promptly to any complications or emergencies that may arise during or after surgery, making necessary adjustments to anesthesia levels or techniques.",
        link: "#",
        location: "Bhubaneswar , Cuttack",
        education: "Medical Degree (MD or DO) with specialization in Anesthesiology.",
        department: "Anesthesiology ",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    },
    {
        id: 11,
        name: "Consultant-Emergency Medicine",
        description: "Provide emergency care, manage trauma cases and rapid response in critical situations.",
        experience: "2 - 7 Years",
        details: "As a Consultant in Emergency Medicine, you will be responsible for providing high-quality emergency medical care to patients in critical and urgent situations.Rapidly assess, diagnose, and manage a wide range of acute medical conditions, including trauma, infections, and medical emergencies.Maintain detailed and accurate medical records, adhering to hospital policies and legal requirements.Stay updated with the latest developments in emergency medicine, participating in ongoing education and training.",
        link: "https://www.naukri.com/job-listings-consultant-emergency-medicine-ashwini-hospital-cuttack-2-to-7-years-260924009876?src=jobsearchDesk&sid=17276887918471435&xp=6&px=1&nignbevent_src=jobsearchDeskGNB",
        location: "Cuttack",
        education: "MD/DNB - Emergency Medicine, SEMI - MEM, MBBS.",
        department: "Emergency Medicine ",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 12,
        name: "Consultant - Plastic Surgery",
        description: "M.Ch/DNB in Plastic Surgery, with exp. in reconstructive surgery, trauma surgery, general plastic surgery, cosmetic surgery prefer.",
        experience: "2 - 7 Years",
        details: "As a Consultant in Plastic Surgery, you will lead and perform advanced plastic and reconstructive surgeries, utilizing your extensive expertise to deliver exceptional patient outcomes. You will guide and mentor junior surgeons, providing training and support to enhance their skills and knowledge. Your role involves developing comprehensive patient care plans, conducting pre-operative evaluations, and post-operative follow-ups to ensure optimal recovery and satisfaction. You will collaborate with multidisciplinary teams to provide integrated care and stay updated with the latest advancements in plastic surgery techniques and technologies.",
        link: "https://www.naukri.com/job-listings-consultant-plastic-surgery-ashwini-hospital-cuttack-2-to-7-years-260924010650?src=jobsearchDesk&sid=17276887918471435&xp=5&px=1&nignbevent_src=jobsearchDeskGNB",
        location: "Cuttack",
        education: "M.Ch/DNB in Plastic Surgery, with exp. in reconstructive surgery, trauma surgery, general plastic surgery, cosmetic surgery pref. with 2-3 years of exp.",
        department: "Plastic Surgery",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: true
    },
    {
        id: 13,
        name: "Nursing In charge",
        description: "Oversee nursing staff, ensure patient care quality, manage schedules, and maintain medical records.",
        experience: "3 - 5 Years",
        details: "The Nursing In-Charge is responsible for overseeing the nursing staff, ensuring the delivery of high-quality patient care, and managing the daily operations of the nursing department. This role involves coordinating patient care activities, supervising nursing personnel, and maintaining accurate medical records.",
        link: "#",
        location: "Bhubaneswar , Cuttack",
        education: "Registered Nurse (RN) with a valid nursing license.",
        department: "Nursing",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    },
    {
        id: 14,
        name: "SAE/Assistant Manager",
        description: "Manage client accounts, drive revenue growth, oversee project execution, and lead client relationship management.",
        experience: "5 Years",
        details: "We are seeking an experienced Senior Account Executive / Assistant Manager to oversee financial operations and manage client accounts within our hospital sector. The ideal candidate will have a strong background in finance and accounting, with expertise in hospital management. This role involves driving revenue growth, ensuring financial accuracy, and leading client relationship management.",
        link: "#",
        location: "Bhubaneswar , Cuttack",
        education: "CA (Inter) or MBA in Finance with minimum of 5 years of experience in finance and accounting within the hospital sector.",
        department: "Finance",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    },
    {
        id: 15,
        name: "Billing Executive",
        description:"Manage invoices, process payments, handle billing queries, and ensure accurate financial records.",
        experience: "2 Years",
        details: "We are seeking a detail-oriented and experienced Billing Executive to manage and oversee the billing operations within our hospital. The ideal candidate will have at least 2 years of experience in a similar role within the healthcare sector and possess a strong background in finance.",
        link: "#",
        location: "Bhubaneswar , Cuttack",
        education: "Bachelor’s degree in Commerce (B.Com) or Science (B.Sc.) ",
        department: "Finance/Billing",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    },
    {
        id: 16,
        name: "Lab Technician",
        description:"Conduct tests and experiments, analyze results, maintain lab equipment, and ensure accurate data reporting.",
        experience: "2 Years",
        details: "As a Lab Technician, you will be responsible for performing tests and experiments to support medical, environmental, or scientific research.Maintaining and calibrating lab equipment to ensure proper functionality.Recording data and generating reports for review by senior staff or researchers.Maintaining and calibrating lab equipment to ensure proper functionality.",
        link: "#",
        location: "Bhubaneswar , Cuttack",
        education: "Associate’s or Bachelor’s degree in Laboratory Technology, Medical Technology, or a related field.",
        department: "Laboratory",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    },
    {
        id: 17,
        name: "Fire and Safety Officer",
        description:"Ensure workplace safety, conduct fire drills, manage emergency procedures, and comply with safety regulations.",
        experience: "2 Years",
        details: "The Fire and Safety Officer is responsible for maintaining a safe working environment by implementing fire prevention measures and ensuring compliance with safety regulations.Ensure adherence to local and national fire safety regulations and standards. Conduct safety audits and inspections to identify potential hazards.Oversee the maintenance and inspection of fire safety equipment, including fire alarms, extinguishers, and sprinkler systems.",
        link: "#",
        location: "Bhubaneswar , Cuttack",
        education: "Bachelor’s degree in Fire Science, Safety Management, or related field.",
        department: "Health and Safety",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    },
    {
        id: 18,
        name: "Pharmacist",
        description:"Dispense medications, provide drug information and ensure safe medication use according to prescriptions and regulations.",
        experience: "2 Years",
        details: "The Fire and Safety Officer is responsible for maintaining a safe working environment by implementing fire prevention measures and ensuring compliance with safety regulations.Ensure adherence to local and national fire safety regulations and standards. Conduct safety audits and inspections to identify potential hazards.Oversee the maintenance and inspection of fire safety equipment, including fire alarms, extinguishers, and sprinkler systems.",
        link: "#",
        location: "Bhubaneswar",
        education: "Bachelor’s degree in Pharmacy or related field.",
        department: "Pharmacy",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Wellness",
        active: false
    },
    {
        id: 19,
        name: "Accounts Executive",
        description:"Manage accounts, process invoices, reconcile financial records, and assist with budgeting and financial reporting.",
        experience: "2 Years",
        details: "he Accounts Executive will manage accounts, process invoices, reconcile financial records, and assist with budgeting and financial reporting.Ensuring accurate and timely processing of invoices and payments.Reconciliation of bank statements and financial records.Maintaining financial records and ensuring compliance with company policies and regulations.",
        link: "#",
        location: "Bhubaneswar",
        education: "Bachelor's degree in Accounting, Finance, or related field.",
        department: "Finance",
        employment_type: " Full Time, Permanent",
        group_name:"Ashwini Group Of Hospitals",
        active: false
    }
];

export default jobs;
