import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import G1 from "../../../assets/department photos/radiology1.png";
import G2 from "../../../assets/department photos/radiology2.png";
import G3 from "../../../assets/department photos/radiology3.png";

function AAHRadiology() {
    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 }
    ];

    const [currentImage, setCurrentImage] = useState('');

    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };

    return (
        <>
            <NavbarLocation />

            {/* Radiology Doctors */}
            <div className="doctors-box">
                <div className="container">

                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Radiology Gallery" title="Radiology Department" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Modal */}
                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Radiology Gallery" title="Radiology Department" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Radiology plays a key role not only in diagnosis but also in the post-operative phase of various surgical procedures, especially in Neuro-Surgery. Neuroradiology methods are used in modern neurosurgery diagnosis and treatment. They include computer assisted imaging computed tomography (CT), perfusion studies, magnetic resonance imaging (MRI) etc.</p>

                                        <p>Our department is equipped with high end 1.5 T MRI capable of executing all brain, spine, body and joint studies. Provision for advanced studies like MR angiography, spectroscopy, perfusion, diffusion, tractography and functional studies are also available.Latest 128 slice multidetector CT scan is the work horse of the department. Apart from general studies of CT brain, spine, body and joint CT scans we are routinely doing all type of CT angiographies including coronary and brain angios and perfusion studies.</p>

                                        <p>With state of art ultrasound machines scans of abdomen, pelvis, soft tissues and skeletal tissues are performed regularly. Doppler studies involving carotid artery, limb artery and veins, renal artery are one of the mainstay investigations in the radiology department.</p>

                                        <p>Obstetric ultrasound, fetal doppler and TIFA scans are done on priority. High end CR system of the hospital provides quality x rays of all kind.</p>

                                        <p>Most importantly our dedicated team of radiologists are committed to standardized high-quality reports round the clock with lesser turnaround time.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Services Available */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Services Available</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Digital X-Ray</li>
                                            <li>Ultrasound</li>
                                            <li>128 Slice Spiral CT Scan</li>
                                            <li>MRI</li>
                                            <li>BMD(Bone  Marrow  Density)</li>
                                            <li>Color Doppler</li>
                                            <li>Echocardiography</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AAHRadiology;