import React from 'react';

const Popup = ({ message, onClose, onConfirm, show  }) => {
    return (
        <div className={`popup ${show ? 'show' : ''}`}>
            <div className="popup-inner">
                <h4>{message}</h4>
                <button onClick={onClose} className="btn btn-secondary">Cancel</button>
                <button onClick={onConfirm} className="btn btn-primary">Visit Website</button>
            </div>
        </div>
    );
};

export default Popup;
