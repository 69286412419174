import ChestXRayImage from "../../assets/Services In Package/x-rays.png";
import HBImage from "../../assets/Services In Package/hemoglobin.png"
import LFTImage from "../../assets/Services In Package/liver.png"
import DcImage from "../../assets/Services In Package/white-blood-cells.png"
import TlcImage from "../../assets/Services In Package/white.png"
import EsrImage from "../../assets/Services In Package/erythrocyte-sedimentation-rate-test.png"
import FbsImage from "../../assets/Services In Package/fasting.png"
import PpbsImage from "../../assets/Services In Package/sugar-blood-level.png"
import NaImage from "../../assets/Services In Package/sodium.png"
import KImage from "../../assets/Services In Package/potassium.png"
import UreaImage from "../../assets/Services In Package/urine.png"
import PftImage from "../../assets/Services In Package/lungs.png"
import EcgImage from "../../assets/Services In Package/ecg.png"
import PeeImage from "../../assets/Services In Package/pee.png"
import DocImage from "../../assets/Services In Package/physician.png"
import StoolImage from "../../assets/Services In Package/stool-analysis.png"
import VdrllImage from "../../assets/Services In Package/vdrl-test.png"
import EcholImage from "../../assets/Services In Package/echocardiography.png"
import LipidlImage from "../../assets/Services In Package/lipid-profile.png"
import UricAcidlImage from "../../assets/Services In Package/uric-acid-test.png"
import HivImage from "../../assets/Services In Package/hiv.png"
import HsbaImage from "../../assets/Services In Package/hepatitis-b.png"
import SonoImage from "../../assets/Services In Package/ultrasonography.png"
import TmtImage from "../../assets/Services In Package/treadmill.png"
import DopplerImage from "../../assets/Services In Package/echocardiogram.png"
import CardioImage from "../../assets/Services In Package/cardiology.png"
import BloodCountImage from "../../assets/Services In Package/blood-count-test.png"
import PttImage from "../../assets/Services In Package/clotting-time-test.png"
import SugarImage from "../../assets/Services In Package/diabetes.png"

export const servicesData = [
    { name: "HB", image: HBImage, description: "Measures hemoglobin levels. Helps diagnose anemia." },
    { name: "DC", image: DcImage, description: "Measures the percentage of different types of white blood cells, helping to diagnose infections, inflammation, and immune system disorders." },
    { name: "TLC", image: TlcImage, description: "Measures the total number of white blood cells in the blood, aiding in the detection of infections, immune system disorders, and certain types of cancers." },
    { name: "ESR", image: EsrImage, description: "Assesses the rate at which red blood cells settle in a test tube, used to detect inflammation, infections, and monitor chronic diseases." },
    { name: "FBS", image: FbsImage, description: "Measures blood glucose levels after fasting, used to screen for diabetes and monitor blood sugar control." },
    { name: "PPBS", image: PpbsImage, description: "Measures blood glucose levels after eating, helping to assess how the body manages sugar and diagnose diabetes." },
    { name: "Na+", image: NaImage, description: "Measures sodium levels in the blood, essential for maintaining fluid balance, nerve function, and muscle function. Abnormal levels can indicate dehydration or kidney problems." },
    { name: "K+", image: KImage, description: "Measures potassium levels in the blood, crucial for maintaining proper heart and muscle function, and regulating fluid balance. " },
    { name: "Urea Creatinine", image: UreaImage, description: "Measures levels of urea and creatinine in the blood to assess kidney function. Elevated levels can indicate kidney impairment or dehydration." },
    { name: "LIVER FUNCTION TEST", image: LFTImage, description: "Evaluates liver health by measuring levels of enzymes, proteins, and substances in the blood. Helps detect liver diseases, inflammation, and damage." },
    { name: "PFT", image: PftImage, description: "Assesses lung capacity and function by measuring airflow and gas exchange. Helps diagnose respiratory conditions like asthma, chronic obstructive pulmonary disease (COPD), and other lung disorders." },
    { name: "ECG", image: EcgImage, description: "Records the electrical activity of the heart, helping to detect arrhythmias, heart attacks, and other heart conditions." },
    { name: "URINE R/M", image: PeeImage, description: "Analyzes urine for various parameters including color, clarity, and presence of cells or substances. Helps diagnose urinary tract infections, kidney diseases, and other conditions." },
    { name: "CHEST X-RAY", image: ChestXRayImage, description: "Provides images of the chest, including the heart, lungs, and bones, to detect conditions like pneumonia, heart failure, and lung abnormalities." },
    { name: "PHYSICIAN CONSULTATION", image: DocImage, description: "A medical evaluation by a physician to assess overall health, discuss symptoms, and provide recommendations or treatment plans based on health check-up results." },
    { name: "URINE & STOOL R/M", image: StoolImage, description: "Analyzes both urine and stool samples for various parameters, helping to diagnose infections, digestive disorders, and other health issues." },
    { name: "VDRL", image: VdrllImage, description: "A blood test used to screen for syphilis, a sexually transmitted infection. It detects antibodies produced in response to the infection." },
    { name: "ECHO SCREENING", image: EcholImage, description: "Uses ultrasound waves to create images of the heart's structure and function, helping to diagnose heart conditions such as valve problems, heart failure, and congenital heart defects." },
    { name: "LIPID PROFILE", image: LipidlImage, description: "Measures levels of various types of fats in the blood, including cholesterol and triglycerides. It helps assess the risk of heart disease and monitor lipid-related health conditions." },
    { name: "URIC ACID", image: UricAcidlImage, description: "Measures the level of uric acid in the blood, which can help diagnose gout, kidney stones, and certain types of kidney dysfunction. Elevated levels may indicate problems with purine metabolism." },
    { name: "HIV", image: HivImage, description: "A test that detects the presence of HIV antibodies or the virus itself in the blood, used to diagnose HIV infection and monitor its progression." },
    { name: "HBSAG", image: HsbaImage, description: "A test that detects the presence of the hepatitis B virus in the blood, indicating an active infection. It helps diagnose and monitor hepatitis B infection." },
    { name: "ULTRA SONOGRAPHY", image: SonoImage, description: "Uses high-frequency sound waves to create images of internal organs and tissues, helping to diagnose and monitor conditions related to the abdomen, pelvis, and other areas." },
    { name: "TMT", image: TmtImage, description: "Also known as a stress test, it evaluates heart function by monitoring heart activity while the patient exercises on a treadmill. It helps diagnose heart disease and assess exercise tolerance." },
    { name: "ECHO DOPPLER", image: DopplerImage, description: "Measures the speed and direction of blood flow in the heart and blood vessels using ultrasound. It helps diagnose issues with blood flow, such as valve problems or blood clots." },
    { name: "CARDIAC ECHO DOPPLER", image: DopplerImage, description: "A type of echocardiography that uses ultrasound to assess blood flow and heart function, providing detailed images and measurements of the heart's chambers, valves, and blood vessels. It helps diagnose and evaluate heart conditions such as valve disease and heart failure." },
    { name: "CARDIOLOGY CONSULTATION", image: CardioImage, description: "A specialized medical evaluation by a cardiologist to assess heart health, discuss symptoms, and develop a treatment plan based on cardiovascular test results and overall health status." },
    { name: "CBC", image: BloodCountImage, description: "A comprehensive blood test that measures various components of blood, including red blood cells, white blood cells, and platelets. It helps diagnose conditions like anemia, infections, and blood disorders." },
    { name: "PTT", image: PttImage, description: "Measures the time it takes for blood to clot, helping to assess blood clotting function and diagnose bleeding disorders or monitor anticoagulant therapy." },
    { name: "Ur/Cr", image: UreaImage, description: "Compares the concentration of creatinine in urine to that in blood, helping to evaluate kidney function and diagnose conditions affecting the kidneys." },
    { name: "ECHO", image: EcholImage, description: "Uses ultrasound waves to produce images of the heart's structure and function. It helps diagnose heart conditions such as valve disorders, heart failure, and congenital heart defects." },
    { name: "LIPIDS", image: LipidlImage, description: "Measures cholesterol and triglycerides levels specifically to evaluate heart health, especially important for assessing cardiovascular risk factors unique to women." },
    { name: "FBS /2hr PPBS", image: SugarImage, description: "Measures blood sugar levels after fasting to check for diabetes or prediabetes.Assesses blood sugar levels two hours after eating to evaluate how the body processes glucose, important for diagnosing diabetes and monitoring glucose control." },
];