import React from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/user.png";

function AHCardiothoracic() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Naba Kumar Pattnaik",
            qualification: "M.Ch(CTVS)",
            location: "Ashwini Hospital"
        }
    ];

    return (
        <>
            <NavbarLocation />

            {/* Cardiothoracic Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                    {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name"href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span className="text-dark">{slide.location}</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Within the Cardiothoracic Surgery Department at Ashwini Hospitals, our commitment to excellence is exemplified by our state-of-the-art facilities and world-class surgical expertise. Our dedicated cardiothoracic operating suites are outfitted with advanced technology, including minimally invasive surgical equipment and intraoperative imaging modalities, enabling our skilled surgeons to perform intricate procedures with unparalleled precision. From coronary artery bypass grafting (CABG) to complex valve repair and replacement surgeries, our team specializes in the full spectrum of cardiac and thoracic interventions. Moreover, our facility includes specialized cardiac catheterization labs for diagnostic and interventional procedures, ensuring comprehensive cardiovascular care under one roof. Beyond surgical excellence, our department places a strong emphasis on patient-centered care, offering comprehensive pre-operative evaluations, perioperative support services, and post-operative cardiac rehabilitation programs to optimize patient outcomes and enhance long-term cardiovascular health.</p>
                                    </div>
                                </div>
                            </div>

                            {/* In Cardiothoracic Division */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>In Cardiothoracic Division</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Coronary Artery bypass surgery (CABG) (on pump and off pump)</li>
                                            <li>Congenital Anomaly correction (ASD, VSD, PDA, TOF)</li>
                                            <li>Pulmonary Lobectomy</li>
                                            <li>Thymectomy</li>
                                            <li>Diaphragmatic Hernia repair</li>
                                            <li>Valve replacement surgery</li>
                                            <li>Arterial embolectomy</li>
                                            <li>Varicose vein surgery</li>
                                            <li>AV Fistula</li>
                                            <li>Vascular injury / Aneurysm repair surgery</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AHCardiothoracic;