import React from "react";
import Neurology from "../../assets/service bg images/neurology.png";
import Neurosurgery from "../../assets/service bg images/neurosurgery.png";
import Cardiology from "../../assets/service bg images/cardiology.png";
import PlasticSurgery from "../../assets/service bg images/plastic_surgery.png";
import Pulmonary from "../../assets/service bg images/pulmonary.png";
import CriticalCare from "../../assets/service bg images/critical_care.png";
import EmergencyMedicine from "../../assets/service bg images/emergency_medicine.png";
import Orthopedics from "../../assets/service bg images/orthopedics.png";

import NeuroIcon from "../../assets/services icons/neurology.png";
import NeurosurgeryIcon from "../../assets/services icons/neurology.png";
import OrthoIcon from "../../assets/services icons/orthopedics.png";
import PSIcon from "../../assets/services icons/burn and plastic.png";
import PulmoIcon from "../../assets/services icons/pulmonology.png";
import CCIcon from "../../assets/services icons/critical care.png";
import EMIcon from "../../assets/services icons/emergency medicine.png";
import CardioIcon from "../../assets/services icons/cardiology.png";

function DepartmentsSection() {
    const services = [
        {
            link: "/Neurology",
            image: Neurology,
            icon: NeuroIcon,
            title: "Neurology"
        },
        {
            link: "/Neurosurgery",
            image: Neurosurgery,
            icon: NeurosurgeryIcon,
            title: "Neurosurgery"
        },
        {
            link: "/Orthopedics",
            image: Orthopedics,
            icon: OrthoIcon,
            title: "Orthopedics Trauma & Rehabilitation"
        },
        {
            link: "/PlasticSurgery",
            image: PlasticSurgery,
            icon: PSIcon,
            title: "Burns, Plastic & Reconstructive Surgery"
        },
        {
            link: "/Pulmonary",
            image: Pulmonary,
            icon: PulmoIcon,
            title: "Pulmonary & Respiratory Medicine"
        },
        {
            link: "/CriticalCare",
            image: CriticalCare,
            icon: CCIcon,
            title: "Critical Care"
        },
        {
            link: "/EmergencyMedicine",
            image: EmergencyMedicine,
            icon: EMIcon,
            title: "Emergency Medicine"
        },
        {
            link: "/Cardiology",
            image: Cardiology,
            icon: CardioIcon,
            title: "Cardiology & Intervention"
        },
    ]

    return (
        <>
            <section className="service-section my-5">
                <div className="container">
                    <div className="sec-title mb-4">
                        <h1>Our Departments</h1>
                    </div>
                    <div className="row">
                        {services.map((service, index) => (
                            <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
                                <div className="service-block-one fadeInUp">
                                    <div className="inner-box">
                                        <div className="image-box">
                                            <div className="image">
                                                <a href={service.link}>
                                                    <img src={service.image} alt={`${service.title} Img`} title={`${service.title} Department`} />
                                                </a>
                                            </div>
                                            <div className="icon-box">
                                                <img src={service.icon} alt="DepartmentImages" />
                                            </div>
                                        </div>
                                        <div className="lower-content">
                                            <span><a href={service.link}>{service.title}</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="btn-box text-center">
                        <a href={"/Departments"} className="theme-btn btn-one">
                            <span>Know More</span>
                        </a>
                    </div>
                </div>
            </section >
        </>
    )
}

export default DepartmentsSection;