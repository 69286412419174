import React, { useState } from "react";
import NavbarLocation from "../../Navbar/NavbarLocation";
import Img1 from "../../../assets/doctors/kanakaya_reddy.png";
import Img2 from "../../../assets/doctors/narayan_behera.png";
import Img3 from "../../../assets/doctors/pravat_dehury.png";
import Img4 from "../../../assets/doctors/sourav_mondal.png";
import G1 from "../../../assets/department photos/ortho1.png";
import G2 from "../../../assets/department photos/ortho2.png";
import G3 from "../../../assets/department photos/ortho3.png";
import G4 from "../../../assets/department photos/ortho4.png";
import G5 from "../../../assets/department photos/ortho5.png";
import G6 from "../../../assets/department photos/ortho6.png";
import G7 from "../../../assets/department photos/ortho7.png";

function ATCOrthopedic() {
    const [openIndex, setOpenIndex] = useState(null);
    const [currentImage, setCurrentImage] = useState('');
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
    };
    const openModal = (image) => {
        setCurrentImage(image);
        const modalElement = document.getElementById('exampleModal');
        const modal = new window.bootstrap.Modal(modalElement);
        modal.show();
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. B Kanakaya Reddy",
            qualification: "M.S(Ortho), DNB Orthopedics, Senior Faculty Orthopedics",
            location: "Ashwini Trauma Centre"
        },
        {
            image: Img2,
            name: "Dr. Satya Narayan Behera",
            qualification: "M.S(Ortho), Fellow in Arthroplasty, Senior Faculty Orthopedics",
            location: "Ashwini Trauma Centre"
        },
        {
            image: Img3,
            name: "Dr. Pravat Dehury",
            qualification: "M.S(Ortho), Junior Consultant Orthopedics",
            location: "Ashwini Trauma Centre"
        },
        {
            image: Img4,
            name: "Dr. Sourav Mondal",
            qualification: "M.S(Ortho), Junior Consultant Orthopedics",
            location: "Ashwini Trauma Centre"
        }
    ];

    const gallery = [
        { image: G1 },
        { image: G2 },
        { image: G3 },
        { image: G4 },
        { image: G5 },
        { image: G6 },
        { image: G7 }
    ];

    const opdTimings = [
        {
            doctor: "Dr. B Kanakaya Reddy",
            schedule: [
                { day: 'TUE', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'THU', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'SAT', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        },
        {
            doctor: 'Dr. Satya Narayan Behera',
            schedule: [
                { day: 'MON', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'WED', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'FRI', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        }
    ]

    return (
        <>
            <NavbarLocation />

            {/* Orthopedics Doctors */}
            <div className="doctors-box">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                    {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name" href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                    <div className="card h-100 doctor_img">
                                        <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                        <div className="doc-text">
                                            <h3>{slide.name}</h3>
                                            <p>{slide.qualification}</p>
                                            <span className="text-dark">{slide.location}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                {opdTimings.map((opd, index) => (
                                        <div className="accordion-item" key={`opd-${index}`}>
                                            <h2 className="accordion-header" id={`heading${index}`}>
                                                <button
                                                    className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                    type="button"
                                                    onClick={() => toggleAccordion(index)}
                                                    aria-expanded={openIndex === index ? "true" : "false"}
                                                    aria-controls={`collapse${index}`}
                                                >
                                                    {opd.doctor}
                                                    <span className="toggle-text">
                                                        {openIndex === index ? "Hide" : "Show"}
                                                    </span>
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent="#opdTimingsAccordion"
                                            >
                                                <div className="accordion-body">
                                                    <table className="table table-hover table-light">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">DAYS</th>
                                                                <th scope="col">TIMINGS</th>
                                                                <th scope="col">CENTRE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {opd.schedule.map((schedule, idx) => (
                                                                <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                    <th scope="row">{schedule.day}</th>
                                                                    <td>{schedule.time}</td>
                                                                    <td>{schedule.centre}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        {gallery.map((g, index) => (
                                            <li key={index}>
                                                <figure><img src={g.image} alt="Orthopedic Gallery" title="Orthopedic Department" onClick={() => openModal(g.image)} /></figure>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Image Preview</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <img src={currentImage} alt="Orthopedic Gallery" title="Orthopedic Department" style={{ width: '100%' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Ashwini Group of Hospitals and out reach clinics (Ashwini Emergency & Trauma Centres) have established their firm credentials as the leading trauma care facility of the Odisha and adjoining States. We run a comprehensive trauma care program, especially for poly-trauma patients from resuscitation and stabilisation to effective rehabilitation of the patient. The team has Orthopedic Surgeons, Spine Surgeons, Plastic Surgeons, Neuro-Surgeons, Vascular Surgeons, Maxillo-Facial Surgeons, Critical Care Physicians and Therapists. With a robust armamentarium, round the clock emergency and dedicated support staff we strive to save as many lives and limbs as possible. Recognising this, Departments of Health and Transport have offered free treatment to road traffic accident victims for first 48 hours of the accident. To name a few procedures, we have trained man power to deal with the following.</p>

                                        <p>Complex pelvic-acetabular fractures (anterior column, bi-columnar, posterior column and wall fractures and transverse fracture), fractures associated with vascular injury and nerve injury, neglected and complicated fracture non-unions treated by Ilizarov techniques and free vascularised fibula transfers. Besides, management of floating knee and complex intra-articular fractures, fractures around hip, shoulder, elbow, hand injuries are managed seamlessly by our team of surgeons. Many fractures are treated by MIPPO and less scar techniques.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Reconstructive Surgery */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Reconstructive Surgery</h1>
                                    </div>
                                    <div className="card-text">
                                        <p>Deformity correction like Genu varum and genu valgum, Coxa vara and coxa valga, Cubitus varus and cubitus valgus deformities, Limb lengthening, Osteotomies around hip for congenital and childhood diseases like DDH and Perthes diseases, CTEV correction in both childhood and adult, cavo-varus deformity and flat foot are done regularly as also contracture releases.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Arthroscopic Surgery */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Arthroscopic Surgery</h1>
                                    </div>
                                    <div className="card-text">
                                        <p><span className="text-title">Shoulder Arthroscopy : </span>Complex rotator cuff tears treated by arthroscopic Rotator Cuff Repair by modified Double Row Suture Bridge technique. For recurrent shoulder dislocations: - Arthroscopy Bankart surgery and Latarjet surgery is offered. Arthroscopic AC joint repair and reconstruction, arthroscopic capsule release for stiff shoulder, arthroscopic SLAP repair, arthroscopic Subacromial decompression for Subacromial bursitis and shoulder impingement are available in our Arthroscopic Surgery Unit. AC joint arthritis treated by arthroscopic partiall resection of AC joint. spino-glenoid cyst with suprascapular nerve compression are treated by arthroscopic decompression.</p>
                                        <p><span className="text-title">Knee Arthroscopy : </span>Procedures done in the knee by arthroscopy include: Arthroscopic ACL reconstruction by single and double bundle techniques, Arthroscopic PCL reconstruction by single and double bundle techniques, Arthroscopic meniscus repair by all-inside, inside-out and outside-in techniques, Complex multiple ligament injuries of knee that is LCL, MCL injuries with latest techniques, Arthroscopic popliteus reconstruction, Osteotomies around knee, MPFL reconstruction for recurrent Patellar Dislocations, Osteochondral defect treatment for Arthroscopic OATS and mosaic-plasty.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Spine Surgery Facilities */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Spine Surgery Facilities</h1>
                                    </div>
                                    <div className="card-text">
                                        <p>Various spine surgery for acute as well as chronic spinal ailments including MIS (Minimal Invasive Surgery) Spine Surgery (for less pain in post-operative period with early recovery) are available at Ashwini group of hospitals. To name a few are, MIS TLIF surgery for spondylolisthesis and spinal canal stenosis and degenerative spine, Microscopic Discectomy, Hemi-laminectomy and Laminectomy, fracture fixation in Dorso-Lumbar Spine region and Balloon kyphoplasty and vertebroplasty.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Joint Replacement Surgery */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Joint Replacement Surgery</h1>
                                    </div>
                                    <div className="card-text">
                                        <p>On a regular basis the following joint replacement surgery are done in 4 modular operation theaters at Ashwini Hospitals.</p>
                                        <ul className="ul_list">
                                            <li>Total knee replacement</li>
                                            <li>HTO for Varus and Valgus deformities</li>
                                            <li>Total hip replacement</li>
                                            <li>Revision Total hip replacement</li>
                                            <li>Revision Reverse Shoulder Replacement</li>
                                            <li>Radial head replacement</li>
                                            <li>Uni-condylar knee replacement (UKR)</li>
                                            <li>Revision Total knee replacement</li>
                                            <li>Complex primary hip replacement</li>
                                            <li>Total shoulder replacement and Reverse Shoulder  replacement</li>
                                            <li>Total elbow replacement</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ATCOrthopedic;