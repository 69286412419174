import React, { useEffect, useRef } from "react";
import './Contact.css'
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import ContactBanner from "../../assets/about_banner.jpg";
import BannerBg from "../../assets/banner-callus.png";
import emailjs from '@emailjs/browser';

function Contact() {
    const form = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_tj5tyam', 'template_1tpu44e', form.current, { publicKey: 'egxcYC6EeJdXDm0et' })
            .then((result) => {
                console.log('SUCCESS!', result.text);
                alert('Email sent successfully');
            }, (error) => {
                console.log('FAILED...', error);
                alert('Failed to send email');
            });

        e.target.reset();
    };

    return (
        <>
            <Navbar />

            {/* Contact Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={ContactBanner} alt="ContactBanner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Contact us */}
            <section className="contact-section py-5">
                <div className="bg-layer">
                    <img src={BannerBg} alt="BannerBackground" />
                </div>

                <div className="container">
                    <div className="row clearfix">
                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className="form-inner">
                                <div className="sec-title">
                                    <h2 className="job">Feel Free To Contact Us</h2>
                                    <p className="text-start">We believe in providing the best possible care to all our existing patients and welcome new patients to sample.</p>
                                </div>

                                <form className="default-form" ref={form} onSubmit={sendEmail}>
                                    <div className="mb-3 form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name*"
                                            name="user_name"
                                            required />
                                    </div>

                                    <div className="row">
                                        <div className="mb-3 form-group col-sm-6">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Phone Number*"
                                                name="user_phone"
                                                required />
                                        </div>
                                        <div className="mb-3 form-group col-sm-6">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email address*"
                                                name="user_email"
                                                required />
                                        </div>
                                    </div>

                                    <div className="mb-3 form-group">
                                        <textarea
                                            className="form-control"
                                            placeholder="Address*"
                                            name="user_address"
                                            required
                                            style={{ height: "130px" }}
                                        ></textarea>
                                    </div>

                                    <div className="text-start mb-3">
                                        <button type="submit" className="theme-btn btn-one">
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="row">
                <div className="mb-3 form-group col-sm-6">
                    <input type="text" className="form-control" placeholder="Occupation*" required />
                </div>
                <div className="mb-3 form-group col-sm-6">
                    <input type="text" className="form-control" placeholder="Rank in Post PG/NEET/SS**" required />
                </div>
            </div>

            <div className="mb-3 form-group">
                <select className="form-select" aria-label="Default select example">
                    <option selected className="option" value="">Select Specialization*</option>
                    <option value="DrNB Neurology">DrNB Neurology</option>
                    <option value="DrNB Neurosurgery">DrNB Neurosurgery</option>
                    <option value="DrNB Plastic Surgery">DrNB Plastic Surgery</option>
                </select>
            </div> */}
        </>
    )
}

export default Contact;