import React, { useState } from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import UrologyBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/user.png";

function Urology() {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. Jateen Anshuman",
            qualification: "DM(Urology)",
            location: "Ashwini Hospital"
        }
    ];

    const opdTimings = [
        {
            doctor: 'Dr. Jateen Anshuman',
            schedule: [
                { day: 'WED', time: '04:00 PM - 05:00 PM', centre: 'Ashwini Hospital' },
                { day: 'THU', time: '04:00 PM - 05:00 PM', centre: 'Ashwini Hospital' },
                { day: 'FRI', time: '04:00 PM - 05:00 PM', centre: 'Ashwini Hospital' },
                { day: 'SAT', time: '04:00 PM - 05:00 PM', centre: 'Ashwini Hospital' }
            ]
        }
    ]

    return (
        <>
            <Navbar />

            {/* Urology Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={UrologyBanner} alt="Urology Banner" title="Urology Banner"/>
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Urology</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Urology Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name"href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span className="text-dark">{slide.location}</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    <div className="accordion" id="opdTimingsAccordion">
                                        {opdTimings.map((opd, index) => (
                                            <div className="accordion-item" key={`opd-${index}`}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button
                                                        className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                        type="button"
                                                        onClick={() => toggleAccordion(index)}
                                                        aria-expanded={openIndex === index ? "true" : "false"}
                                                        aria-controls={`collapse${index}`}
                                                    >
                                                        {opd.doctor}
                                                        <span className="toggle-text">
                                                            {openIndex === index ? "Hide" : "Show"}
                                                        </span>
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`collapse${index}`}
                                                    className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-bs-parent="#opdTimingsAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <table className="table table-hover table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">DAYS</th>
                                                                    <th scope="col">TIMINGS</th>
                                                                    <th scope="col">CENTRE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {opd.schedule.map((schedule, idx) => (
                                                                    <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                        <th scope="row">{schedule.day}</th>
                                                                        <td>{schedule.time}</td>
                                                                        <td>{schedule.centre}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>At Ashwini Group of Hospitals, our Urology Department is dedicated to providing comprehensive care for patients with urological conditions. Our team of experienced urologists specializes in diagnosing and treating a wide range of conditions affecting the urinary tract and male reproductive system. From kidney stones and urinary tract infections to prostate cancer and erectile dysfunction, we offer personalized treatment plans tailored to each patient's unique needs. With state-of-the-art facilities and advanced surgical techniques, we strive to deliver the highest quality of care with compassion and expertise.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Urology Services */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>The services provided by the Urology unit</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Diagnostic  Laparoscopy</li>
                                            <li>Diagnostic Cystoscopy</li>
                                            <li>MCU (Micturating cystourethrogram)</li>
                                            <li>RGU (Retrograde Urethrogram)</li>
                                            <li>CT Urography</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Urology;