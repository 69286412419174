import React from 'react';
import "../../App.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import P1 from "../../assets/partners/1.png";
import P2 from "../../assets/partners/2.png";
import P3 from "../../assets/partners/3.png";
import P4 from "../../assets/partners/4.png";
import P5 from "../../assets/partners/5.png";
import P6 from "../../assets/partners/6.png";
import P7 from "../../assets/partners/7.png";

function Partners() {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section className="partners-section py-5">
            <div className="container">
                <div className="sec-title">
                    <h1>Our Partners</h1>
                </div>
                <div className="slider-container">
                    <Slider {...settings}>
                        <div>
                            <img className='img-fluid partners-img' src={P1} alt="Partner1" />
                        </div>
                        <div>
                            <img className='img-fluid partners-img' src={P2} alt="Partner2" />
                        </div>
                        <div>
                            <img className='img-fluid partners-img' src={P3} alt="Partner3" />
                        </div>
                        <div>
                            <img className='img-fluid partners-img' src={P4} alt="Partner4" />
                        </div>
                        <div>
                            <img className='img-fluid partners-img' src={P5} alt="Partner5" />
                        </div>
                        <div>
                            <img className='img-fluid partners-img' src={P6} alt="Partner6" />
                        </div>
                        <div>
                            <img className='img-fluid partners-img' src={P7} alt="Partner7" />
                        </div>
                    </Slider>
                </div>
            </div>
        </section >
    )
}

export default Partners;