import React, { useRef } from "react";
import "../../App.css";
import AppointmentBg from "../../assets/contact_imgBg.png";
import Appointment from "../../assets/contact_img.png";
import Arrow from "../../assets/shapes/arrow.png";
import emailjs from '@emailjs/browser';

function Booking() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_mtn05ga', 'template_s01htbp', form.current, { publicKey: 'egxcYC6EeJdXDm0et' })
            .then((result) => {
                console.log('SUCCESS!', result.text);
                alert('Email sent successfully');
            }, (error) => {
                console.log('FAILED...', error);
                alert('Failed to send email');
            });

        e.target.reset();
    };

    return (
        <section className="booking-section">
            <div className="bg-layer parallax-bg" data-parallax="{&quot;y&quot;: 100}">
                <img src={AppointmentBg} alt="AppointmentBg" />
            </div>
            <figure className="image-layer d-none d-lg-block">
                <img src={Appointment} alt="Appointment" />
            </figure>
            
            <div className="container">
                <div className="inner-box">
                    <div className="shape d-none d-lg-block">
                        <img src={Arrow} alt="Arrow" />
                    </div>
                    <h1>Online Consultations With <br />Qualified Doctors</h1>

                    <div className="d-flex">
                        <div className="btn-box">
                            <button type="button" className="theme-btn btn-one" data-bs-toggle="modal" data-bs-target="#appointmentModal">
                                <span>Make an Appointment</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* modal */}
            <div className="modal fade" id="appointmentModal" tabIndex="-1" aria-labelledby="appointmentModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title course_title" id="appointmentModalLabel">Request Form</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form className="default-form" ref={form} onSubmit={sendEmail}>
                                <div className="mb-3 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Your Name*"
                                        name="user_name"
                                        required />
                                </div>

                                <div className="row">
                                    <div className="mb-3 form-group col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Phone Number*"
                                            name="user_phone"
                                            required />
                                    </div>
                                    <div className="mb-3 form-group col-sm-6">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Email address*"
                                            name="user_email"
                                            required />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="mb-3 form-group col-sm-6">
                                        <input
                                            type="date"
                                            className="form-control"
                                            placeholder="Date*"
                                            name="user_date"
                                            required />
                                    </div>
                                    <div className="mb-3 form-group col-sm-6">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Department*"
                                            name="user_department"
                                            required />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Request</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Booking;