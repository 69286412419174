import React, { useState } from "react";
import "./ServiceDetails.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import HandSurgeryBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/subrat_jena.png";
import Img2 from "../../assets/doctors/kanakaya_reddy.png";

function HandSurgery() {
    const [openIndex, setOpenIndex] = useState(null);
    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle accordion open/close
    };
    const slides = [
        {
            image: Img1,
            name: "Dr. Subrat Kumar Jena",
            qualification: "M.S(Ortho), M.Ch(Plastic Surgery)",
            location: "Ashwini Trauma Centre"
        },
        {
            image: Img2,
            name: "Dr. B Kanakaya Reddy",
            qualification: "M.S(Ortho), DNB Orthopedics, Senior Faculty Orthopedics",
            location: "Ashwini Trauma Centre"
        }
    ];

    const opdTimings = [
        {
            doctor: "Dr. Subrat Kumar Jena",
            schedule: [
                { day: 'MON', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'WED', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'FRI', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        },
        {
            doctor: "Dr. B Kanakaya Reddy",
            schedule: [
                { day: 'TUE', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'THU', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' },
                { day: 'SAT', time: '10:00 AM - 02:00 PM', centre: 'Ashwini Trauma Centre' }
            ]
        }
    ]

    return (
        <>
            <Navbar />

            {/* HandSurgery Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={HandSurgeryBanner} alt="Hand Surgery Banner" title="Hand Surgery Banner"/>
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Hand & Micro Vascular Surgery</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* HandSurgery Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                            <a className="doc-name"href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt={`${slide.name} Img`} title={slide.name} />
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span className="text-dark">{slide.location}</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* opd timings */}
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">OPD Timings</h2>
                                <div className="widget-content">
                                    <div className="accordion" id="opdTimingsAccordion">
                                        {opdTimings.map((opd, index) => (
                                            <div className="accordion-item" key={`opd-${index}`}>
                                                <h2 className="accordion-header" id={`heading${index}`}>
                                                    <button
                                                        className={`accordion-button ${openIndex === index ? "" : "collapsed"}`}
                                                        type="button"
                                                        onClick={() => toggleAccordion(index)}
                                                        aria-expanded={openIndex === index ? "true" : "false"}
                                                        aria-controls={`collapse${index}`}
                                                    >
                                                        {opd.doctor}
                                                        <span className="toggle-text">
                                                            {openIndex === index ? "Hide" : "Show"}
                                                        </span>
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`collapse${index}`}
                                                    className={`accordion-collapse collapse ${openIndex === index ? "show" : ""}`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-bs-parent="#opdTimingsAccordion"
                                                >
                                                    <div className="accordion-body">
                                                        <table className="table table-hover table-light">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">DAYS</th>
                                                                    <th scope="col">TIMINGS</th>
                                                                    <th scope="col">CENTRE</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {opd.schedule.map((schedule, idx) => (
                                                                    <tr className="opd-table" key={`schedule-${index}-${idx}`}>
                                                                        <th scope="row">{schedule.day}</th>
                                                                        <td>{schedule.time}</td>
                                                                        <td>{schedule.centre}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>Hand surgery is concerned with acute injuries and chronic diseases of the hand and wrist, correction of congenital malformations of the upper extremities, and peripheral nerve problems (such as brachial plexus injuries or carpal tunnel syndrome). Hand surgery is an important part of training in plastic surgery, as well as microsurgery, which is necessary to replant an amputated extremity. The hand surgery field is also practiced by orthopedic surgeons. Scar tissue formation after surgery can be problematic on the delicate hand, causing loss of dexterity and digit function if severe enough. After a specialised training, a hand surgeon addresses many simple and complicated issues resulting from birth anomalies like thumb duplication, multiple hand and finger deformities (polydactyly, syndactyly, campto-dactyly, radial club hand etc). They also perform post traumatic hand reconstructions involving bony skeleton of the hand and soft tissue involving tendons, nerves and vessels.</p>

                                        <p>Microsurgery is generally concerned with the reconstruction of missing tissues by transferring a piece of tissue to the reconstruction site and reconnecting blood vessels. Popular subspecialty areas are breast reconstruction, head and neck reconstruction, hand surgery/replantation, and brachial plexus surgery.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HandSurgery;