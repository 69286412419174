import React from "react";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import LaboratoryServicesBanner from "../../assets/about_banner.jpg";
import Img1 from "../../assets/doctors/smrutilata_sahoo.png";
import Img2 from "../../assets/doctors/user.png";

function LaboratoryServices() {
    const slides = [
        {
            image: Img1,
            name: "Dr. Smrutilata Sahoo",
            qualification: "MD(Microbiology), Infection Control Officer",
            location: "Ashwini Hospital"
        },
        {
            image: Img2,
            name: "Dr. Akshay Samantaray",
            qualification: "MD(Pathology)",
            location: "Ashwini Hospital"
        }
    ];

    return (
        <>
            <Navbar />

            {/* Laboratory Services Banner */}
            <section className="page-title text-center">
                <div className="bg-layer">
                    <img src={LaboratoryServicesBanner} alt="Laboratory Services Banner" title="Laboratory Services Banner" />
                </div>
                <div className="container">
                    <div className="content-box">
                        <ul className="bread-crumb clearfix">
                            <li><Link to={"/"}>Home</Link></li>
                            <li><Link to={"/Departments"}>Departments</Link></li>
                            <li>Laboratory Services</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Laboratory Services Doctors */}
            <div className="my-3">
                <div className="container">
                    <div className="sec-title">
                        <h1>Our Esteemed Consultants</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                        {slides.map((slide, index) => (
                            <div className="col-lg-3 col-md-4" key={index}>
                                <a className="doc-name"href={`/Doctors/${slide.name.replace(/\s+/g, '').toLowerCase()}`}>
                                <div className="card h-100 doctor_img">
                                    <img src={slide.image} alt={`${slide.name} Img`} title={slide.name}/>
                                    <div className="doc-text">
                                        <h3>{slide.name}</h3>
                                        <p>{slide.qualification}</p>
                                        <span className="text-dark">{slide.location}</span>
                                    </div>
                                </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
                        {/* gallery photos */}
                        <div className="card">
                            <div className="card-body">
                                <h2 className="card-title widget-title mb-3">Media</h2>
                                <div className="widget-content clearfix">
                                    <ul className="image-list">
                                        <p>To be added</p>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 mb-3">
                        <div className="card">
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="card-text">
                                        <p>The Pathology Department at Ashwini Group of Hospitals plays a vital role in diagnosing and monitoring diseases through the examination of tissues, blood, and other bodily fluids. Our team of pathologists utilizes cutting-edge technology and methodologies to provide accurate and timely results to aid in the treatment and management of various medical conditions. Whether it's analyzing biopsy samples, conducting molecular testing, or interpreting laboratory findings, our commitment to excellence ensures that patients receive the highest standard of care. We work closely with other departments to deliver integrated healthcare solutions that prioritize patient well-being and outcomes.</p>
                                    </div>
                                </div>
                            </div>

                            {/* Services Available  */}
                            <div className="card about-style-two py-0">
                                <div className="container card-body">
                                    <div className="sec-title">
                                        <h1>Services Available</h1>
                                    </div>
                                    <div className="card-text">
                                        <ul className="ul_list">
                                            <li>Biochemistry</li>
                                            <li>Microbiology</li>
                                            <li>Clinical Pathology</li>
                                            <li>Serology</li>
                                            <li>Haematology</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LaboratoryServices;