import React from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { healthPackages } from "../Data/healthPack";

function HealthPackages() {
    return (
        <>
            <section className="healthpackage-section py-5">
                <div className="container">
                    <div className="sec-title mb-4">
                        <h1>Ashwini Preventive Health Check-Up Packages</h1>
                    </div>

                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {healthPackages.map((packageItem, index) => (
                            <div className="col healthpackage-block-one" key={index}>
                                <div className="card h-100 inner-box">
                                    <img src={packageItem.image} className="card-img-top" alt={packageItem.alt} />
                                    <div className="card-body healthpackage-title">
                                        <h5 className="card-title">{packageItem.title}</h5>
                                    </div>
                                    <div className="card-footer show_package">
                                        <button className="small">
                                            <a className="text-decoration-none text-secondary" href={`/health-package/${encodeURIComponent(packageItem.title)}`}>View More Info <MdOutlineKeyboardDoubleArrowRight className="fs-4" /></a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                </div>
            </section>
        </>
    );
}

export default HealthPackages;
